const Symbols = () => {
	return (
		<svg display="none">
			<symbol viewBox="0 0 32 32" id="location">
				<path d="M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z" />
			</symbol>

			<symbol viewBox="0 0 48 48" fill="none" id="selected">
				<circle cx="24" cy="24" r="24" fill="#4BA82E" />
				<path d="M29.8343 18.6342L21.5999 26.8686L18.1655 23.4342C17.8529 23.1216 17.3465 23.1216 17.0345 23.4342C16.7225 23.7468 16.7219 24.2532 17.0345 24.5652L21.0347 28.5654C21.3473 28.878 21.8537 28.878 22.1657 28.5654L30.9659 19.7652C31.2785 19.4526 31.2785 18.9462 30.9659 18.6342C30.6533 18.3222 30.1469 18.3216 29.8349 18.6342H29.8343Z" fill="white" stroke="white" />
			</symbol>

			<symbol viewBox="0 0 22 13" fill="none" id="arrow-up">
				<path d="M21.5405 10.2264L12.1114 0.476234C11.4973 -0.158745 10.5025 -0.158745 9.88965 0.476234L0.460553 10.2264C-0.153517 10.8614 -0.153517 11.89 0.460553 12.5238C1.07462 13.1575 2.06939 13.1587 2.68228 12.5238L10.9999 3.92291L19.3176 12.5238C19.9316 13.1587 20.9264 13.1587 21.5393 12.5238C22.1522 11.8888 22.1545 10.8601 21.5405 10.2264Z" fill="#4BA82E" />
			</symbol>

			<symbol viewBox="0 0 32 32" id="search">
				<path d="M12.688 18.688q2.5 0 4.251-1.751t1.751-4.251-1.751-4.251-4.251-1.751-4.251 1.751-1.751 4.251 1.751 4.251 4.251 1.751zM20.688 18.688l6.625 6.625-2 2-6.625-6.625v-1.063l-0.375-0.375q-2.375 2.063-5.625 2.063-3.625 0-6.156-2.5t-2.531-6.125 2.531-6.156 6.156-2.531 6.125 2.531 2.5 6.156q0 1.312-0.625 2.969t-1.437 2.656l0.375 0.375h1.063z" />
			</symbol>

			<symbol viewBox="0 0 22 23" fill="none" id="close-x">
				<rect y="2" width="2" height="29" transform="rotate(-45 0 2)" fill="white" />
				<rect x="1.41406" y="22.5061" width="2" height="29" transform="rotate(-135 1.41406 22.5061)" fill="white" />
			</symbol>

			<symbol viewBox="0 0 22 23" fill="none" id="close-x-black">
				<rect y="2" width="2" height="29" transform="rotate(-45 0 2)" fill="#4a4a4a" />
				<rect x="1.41406" y="22.5061" width="2" height="29" transform="rotate(-135 1.41406 22.5061)" fill="#4a4a4a" />
			</symbol>

			<symbol viewBox="0 0 18 18" fill="none" id="info">
				<path d="M7.38293e-07 9C5.21086e-07 11.4846 1.00818 13.7365 2.63612 15.3639C4.26406 16.9912 6.51483 18 9 18C11.4852 18 13.7366 16.9918 15.3639 15.3639C16.9912 13.7359 18 11.4852 18 9C18 6.51483 16.9918 4.26345 15.3639 2.63612C13.7359 1.00879 11.4852 -2.3665e-06 9 -2.58376e-06C6.51483 -2.80102e-06 4.26345 1.00818 2.63612 2.63612C1.0088 4.26406 9.55553e-07 6.51483 7.38293e-07 9ZM1.63653 9C1.63653 6.96584 2.4594 5.12681 3.79341 3.79341C5.12743 2.46001 6.96646 1.63653 9 1.63653C11.0335 1.63653 12.8732 2.4594 14.2066 3.79341C15.54 5.12743 16.3635 6.96645 16.3635 9C16.3635 11.0335 15.5406 12.8732 14.2066 14.2066C12.8726 15.54 11.0335 16.3635 9 16.3635C6.96646 16.3635 5.12682 15.5406 3.79341 14.2066C2.46001 12.8726 1.63653 11.0335 1.63653 9ZM9.81796 12.2724L9.81796 9C9.81796 8.54837 9.45163 8.18204 9 8.18204C8.54837 8.18204 8.18204 8.54837 8.18204 9L8.18204 12.2724C8.18204 12.7241 8.54837 13.0904 9 13.0904C9.45163 13.0904 9.81796 12.7241 9.81796 12.2724V12.2724ZM9 4.90898C8.54838 4.90898 8.18204 5.27531 8.18204 5.72694C8.18204 6.17856 8.54837 6.5449 9 6.5449C9.45163 6.5449 9.81796 6.17856 9.81796 5.72694C9.81796 5.27531 9.45163 4.90898 9 4.90898V4.90898Z" fill="#4BA82E" />
			</symbol>

			<symbol viewBox="0 0 24 24" fill="none" id="scroll">
				<circle cx="12" cy="12" r="10" fill="white" />
				<path d="M23 12C23 8.963 21.768 6.211 19.778 4.222C17.788 2.233 15.037 1 12 1C8.963 1 6.211 2.232 4.222 4.222C2.233 6.212 1 8.963 1 12C1 15.037 2.232 17.789 4.222 19.778C6.212 21.767 8.963 23 12 23C15.037 23 17.789 21.768 19.778 19.778C21.767 17.788 23 15.037 23 12ZM21 12C21 14.486 19.994 16.734 18.364 18.364C16.734 19.994 14.486 21 12 21C9.514 21 7.266 19.994 5.636 18.364C4.006 16.734 3 14.486 3 12C3 9.514 4.006 7.266 5.636 5.636C7.266 4.006 9.514 3 12 3C14.486 3 16.734 4.006 18.364 5.636C19.994 7.266 21 9.514 21 12ZM11 8V13.586L8.707 11.293C8.316 10.902 7.683 10.902 7.293 11.293C6.903 11.684 6.902 12.317 7.293 12.707L11.293 16.707C11.385 16.799 11.495 16.873 11.617 16.924C11.862 17.025 12.138 17.025 12.383 16.924C12.501 16.875 12.611 16.803 12.707 16.707L16.707 12.707C17.098 12.316 17.098 11.683 16.707 11.293C16.316 10.903 15.683 10.902 15.293 11.293L13 13.586V8C13 7.448 12.552 7 12 7C11.448 7 11 7.448 11 8V8Z" fill="#4a4a4a" />
			</symbol>

			<symbol viewBox="0 0 32 32" fill="none" id="scroll-2">
				<path d="M9.875 11.437l6.125 6.125 6.125-6.125 1.875 1.875-8 8-8-8z" fill="#262626" />
			</symbol>
		</svg>
	)
}

export default Symbols