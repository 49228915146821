import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Vehicle } from './components/Vehicle';
import { Content } from './components/Content';
import { ContactForm } from './components/ContactForm';
import { ReloadFiltersBlock } from './components/ReloadFiltersBlock';
import './compatibility.js';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/vehicleinfo' component={Vehicle} />
                <Route path='/content' component={Content} />
                <Route path='/contact' component={ContactForm} />
                <Route path='/reloadfiltersblock' component={ReloadFiltersBlock} />
            </Layout>
        );
    }
}
