import React, { Component} from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Link, Route } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import { VehicleSearchFilters } from './VehicleSearchFilters';
import { ResetFiltersButton } from './ResetFiltersButton';
import { PrimaryBannerButton } from './PrimaryBannerButton';
import GoogleTagManager from './GoogleTagManager';

import '../custom.css';
import './Home.css';

import smallSearch from '../images/icon-search.svg';
// Fallback car images
import carImageCitigo from '../images/cars/citigo.png';
import carImageFabia from '../images/cars/fabia.png';
import carImageKaroq from '../images/cars/karoq.png';
import carImageKamiq from '../images/cars/kamiq.png';
import carImageKodiaq from '../images/cars/kodiaq.png';
import carImageOctavia from '../images/cars/octavia.png';
import carImageScala from '../images/cars/scala.png';
import carImageSuperb from '../images/cars/superb.png';
import carImageRapid from '../images/cars/rapid.png'

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        let vehicleSearchFilters = props.location.state !== undefined && props.location.state !== null
            && props.location.state.vehicleSearchFilterValues !== undefined && props.location.state.vehicleSearchFilterValues !== null ?
            JSON.parse(props.location.state.vehicleSearchFilterValues) : null;

        if (vehicleSearchFilters === null) {
            vehicleSearchFilters = JSON.parse(sessionStorage.getItem('session_vehicleSearchFilterValues'));
        }

        this.state = {
            vehicleSearchResult: [],
            vehicleSearchResultIndex: -1,
            loading: true,
            top: 30,
            skip: 0,
            dropdownOpenModelName: false,
            dropdownOpenLocation: false,
            setDropdownOpen: false,
            isMoreOpen: false,
            fallbackCarImages: {
                "Citigo": carImageCitigo,
                "Fabia": carImageFabia,
                "Karoq": carImageKaroq,
                "Kamiq": carImageKamiq,
                "Kodiaq": carImageKodiaq,
                "Octavia": carImageOctavia,
                "Scala": carImageScala,
                "Superb": carImageSuperb,
                "Rapid": carImageRapid
            },
            vehicleSearchFilterValues: {
                selectedModelNames: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedModelNames : [],
                isBodyTypeSedanSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeSedanSelected : false,
                isBodyTypeFarmariSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeFarmariSelected : false,
                isBodyTypeHacthbackSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeHacthbackSelected  : false,
                isBodyTypeSUVSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeSUVSelected : false,
                selectedBodyTypeIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedBodyTypeIds : [],
                isFuelBensiiniSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelBensiiniSelected : false,
                isFuelDieselSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelDieselSelected : false,
                isFuelKaasuSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelKaasuSelected : false,
                isFuelSahkoSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelSahkoSelected : false,
                isFuelHybridiSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelHybridiSelected : false,
                selectedFuelIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedFuelIds : [],
                isTransmissionManualSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isTransmissionManualSelected : false,
                isTransmissionAutomaticSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isTransmissionAutomaticSelected : false,
                selectedTransmissionIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedTransmissionIds : [],
                isFourWheelDriveSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFourWheelDriveSelected : false,
                askPrice: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.askPrice.min : 0,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.askPrice.max : 80000
                },
                mileage: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.mileage.min : 0,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.mileage.max : 130000
                },
                year: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.year.min : (new Date().getFullYear()) - 5,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.year.max : new Date().getFullYear()
                },
                locations: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.locations : [],
                selectedLocationIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null && vehicleSearchFilters.selectedLocationIds !== undefined ? vehicleSearchFilters.selectedLocationIds : [],
                isTowHitchSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isTowHitchSelected : false,
                isFuelDrivenAuxiliaryHeaterSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelDrivenAuxiliaryHeaterSelected : false,
                isCruiseControlSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isCruiseControlSelected : false,
                isMetallicSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isMetallicSelected : false,
                accessories: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.accessories : [],
                co2: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.co2.min : -1,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.co2.max : 220
                }
            }
        };

        this.renderVehicleSearchResult = this.renderVehicleSearchResult.bind(this);
        this.renderVehicles = this.renderVehicles.bind(this);

        this.handleOnDocumentBottom = this.handleOnDocumentBottom.bind(this);
    }

    handleOnDocumentBottom = () => {
        console.log('Scrolled to bottom! ')

        if (this.state.vehicleSearchResultIndex > -1 &&
            this.state.vehicleSearchResult[this.state.vehicleSearchResultIndex].totalResultCount > this.state.top) {
            this.setState({
                top: this.state.top + 30,
                skip: this.state.skip + 30,
                loading: true
            }, () => { this.componentDidMount(true) });
        }
    }

    renderVehicles(vehicleSearchResultChunk) {
        return (
            vehicleSearchResultChunk.inventoryVehicles.map((vehicle, i) =>
                <Col xs='12' sm='12' md='12' lg xl key={vehicle.id}>
                    <div className="search-result-car">
                        <Link to={{
                            pathname: "/vehicleinfo", search: "?id=" + vehicle.id,
                            state: {
                                vehicleSearchResult: this.state.vehicleSearchResult,
                                vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                            }
                        }}>
                            <img src={vehicle.images.marketingImageUrls.length ? vehicle.images.marketingImageUrls[0].replace(".jpg", "_220.jpg").replace(".JPG", "_220.JPG") : this.state.fallbackCarImages[vehicle.modelName]} alt={vehicle.makeName + ' rek.nro. ' + vehicle.formattedLicensePlateNumber} className="search-result-car-img" onError={(e) => { e.target.onerror = null; e.target.src = this.state.fallbackCarImages[vehicle.modelName] }} />
                            <p className="search-result-car-model">{vehicle.modelName}</p>
                            <p className="search-result-car-info">{vehicle.modelYear} | {vehicle.kilometrage} 000 km</p>
                            <p className="search-result-car-price">
                            <NumberFormat value={vehicle.askPrice} displayType={'text'} thousandSeparator={' '} /> &euro;</p>
                        </Link>
                    </div>
                </Col>
            ) 
        );
    }

    renderVehicleSearchResult(vehicleSearchResult) { 

        let searchResultCount = vehicleSearchResult != null && this.state.vehicleSearchResultIndex > -1 ?
            vehicleSearchResult[this.state.vehicleSearchResultIndex].totalResultCount : 0;

        if (this.state.loading && this.state.vehicleSearchResultIndex < 0) {
            return (
                <Row>
                    <Col className='quick-search' xs='12' sm='12' md='12' lg='3'>
                        <div className='quick-search-heading-area'>
                            <p className='quick-search-heading pt-4'><img src={smallSearch} alt="Hae" /> Pikahakukriteerit</p>
                        </div>
                        <div className='quick-search-result pl-4 pt-2 pb-2'>
                            L&ouml;ytyi <strong>{searchResultCount}</strong> Loisto-autoa
                        </div>
                        <Route render={({ history }) => (
                            <ResetFiltersButton
                                history={history} />
                        )} />

                        <Route render={({ history }) => (
                            <VehicleSearchFilters
                                history={history}
                                currentVehicleSearchFilterValues={JSON.stringify(this.state.vehicleSearchFilterValues)} />
                        )} />
                    </Col>
                    <Col className='search-results' xs='0' sm='0' md='0' lg='9'>
                        <Row>
                            <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                <div className='heading-area pl-5'>
                                    <h1>Loisto <span>- vaihtoautojen valioyksilöt</span></h1>
                                </div>
                            </Col>
                        </Row>
                        <div className="loading" key="loading">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </div>
                    </Col>
                </Row>
            );

        }
        else {
            if (vehicleSearchResult[0].totalResultCount > 0) {
                let vehicles = [];
                vehicleSearchResult.map((vehicleSearchResultChunk, i) => vehicles.push(this.renderVehicles(vehicleSearchResultChunk)));
                return (
                    <Row>
                        <Col className='quick-search' xs='12' sm='12' md='12' lg='3'>
                            <div className='quick-search-heading-area'>
                                <p className='quick-search-heading pt-4'><img src={smallSearch} alt="Hae" /> Pikahakukriteerit</p>
                            </div>
                            <div className='quick-search-result pl-4 pt-2 pb-2'>
                                L&ouml;ytyi <strong>{searchResultCount}</strong> Loisto-autoa
                            </div>
                            <Route render={({ history }) => (
                                <ResetFiltersButton
                                    history={history} />
                            )} />

                            <Route render={({ history }) => (
                                <VehicleSearchFilters
                                    history={history}
                                    currentVehicleSearchFilterValues={JSON.stringify(this.state.vehicleSearchFilterValues)} />
                            )} />
                        </Col>
                        <Col className='search-results' xs='0' sm='0' md='0' lg='9'>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                    <div className='heading-area pl-5'>
                                        <h1>Loisto <span>- vaihtoautojen valioyksilöt</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <div className='vehicleGrid'>
                                {vehicles}
                            </div>
                        </Col>
                    </Row>
                );
            }
            else {
                return (
                    <Row>
                        <Col className='quick-search' xs='12' sm='12' md='12' lg='3'>
                            <div className='quick-search-heading-area'>
                                <p className='quick-search-heading pt-4'><img src={smallSearch} alt="Hae" /> Pikahakukriteerit</p>
                            </div>
                            <div className='quick-search-result pl-4 pt-2 pb-2'>
                                L&ouml;ytyi <strong>{searchResultCount}</strong> Loisto-autoa
                            </div>
                            <Route render={({ history }) => (
                                <ResetFiltersButton
                                    history={history} />
                            )} />

                            <Route render={({ history }) => (
                                <VehicleSearchFilters
                                    history={history}
                                    currentVehicleSearchFilterValues={JSON.stringify(this.state.vehicleSearchFilterValues)} />
                            )} />
                        </Col>
                        <Col className='search-results' xs='0' sm='0' md='0' lg='9'>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                    <div className='heading-area pl-5'>
                                        <h1>Loisto <span>- vaihtoautojen valioyksilöt</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <div className="search-result-not-found">Antamillasi hakuehdoilla ei l&ouml;ydy autoja</div>
                        </Col>
                    </Row>
                );
            }
        }
    }


    render() {
 
        let contents = this.renderVehicleSearchResult(this.state.vehicleSearchResult);
        let bannerClassName = "banner";
        if (window.supportsWebp === false) {
            bannerClassName = "banner-no-webp";
        }
        const event = { platform: 'react-stack' }
        return (
            <Container>
                {/*<GoogleTagManager gtmId='GTM-5KVJGP3' scriptId='gtm-script-container' dataLayerName='home' additionalEvents={event} previewVariables='' />*/}
                <div className={bannerClassName}>
                    <Row>
                        <Col xs='0' sm='0' md='0' lg='10' xl='10'>
                        <div className="bannerTextArea">
                            <p className="bannerTitle">Loisto</p>
                                <p className="bannerText">Vaihtoautojen valioyksilöt</p>
                                <Route render={({ history }) => (
                                    <PrimaryBannerButton
                                        history={history} contentPagePath="/content" contentPageTitle="Lue lisää" />
                                )} />
                        </div>
                        </Col>
                    </Row>
                </div>

                {contents}

                <BottomScrollListener onBottom={this.handleOnDocumentBottom} />

            </Container>
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        let vehicleSearchFilters = nextProps.location.state !== undefined && nextProps.location.state !== null
            && nextProps.location.state.vehicleSearchFilterValues !== undefined && nextProps.location.state.vehicleSearchFilterValues !== null ?
            JSON.parse(nextProps.location.state.vehicleSearchFilterValues) : null;

        if (vehicleSearchFilters !== null) {
            if (vehicleSearchFilters.selectedModelNames.length !== prevState.vehicleSearchFilterValues.selectedModelNames.length ||
                vehicleSearchFilters.isBodyTypeSedanSelected !== prevState.vehicleSearchFilterValues.isBodyTypeSedanSelected ||
                vehicleSearchFilters.isBodyTypeFarmariSelected !== prevState.vehicleSearchFilterValues.isBodyTypeFarmariSelected ||
                vehicleSearchFilters.isBodyTypeHacthbackSelected !== prevState.vehicleSearchFilterValues.isBodyTypeHacthbackSelected ||
                vehicleSearchFilters.isBodyTypeSUVSelected !== prevState.vehicleSearchFilterValues.isBodyTypeSUVSelected ||
                vehicleSearchFilters.isFuelBensiiniSelected !== prevState.vehicleSearchFilterValues.isFuelBensiiniSelected ||
                vehicleSearchFilters.isFuelDieselSelected !== prevState.vehicleSearchFilterValues.isFuelDieselSelected ||
                vehicleSearchFilters.isFuelKaasuSelected !== prevState.vehicleSearchFilterValues.isFuelKaasuSelected ||
                vehicleSearchFilters.isFuelSahkoSelected !== prevState.vehicleSearchFilterValues.isFuelSahkoSelected ||
                vehicleSearchFilters.isFuelHybridiSelected !== prevState.vehicleSearchFilterValues.isFuelHybridiSelected ||
                vehicleSearchFilters.isTransmissionManualSelected !== prevState.vehicleSearchFilterValues.isTransmissionManualSelected || 
                vehicleSearchFilters.isTransmissionAutomaticSelected !== prevState.vehicleSearchFilterValues.isTransmissionAutomaticSelected ||
                vehicleSearchFilters.isFourWheelDriveSelected !== prevState.vehicleSearchFilterValues.isFourWheelDriveSelected ||
                vehicleSearchFilters.askPrice.min !== prevState.vehicleSearchFilterValues.askPrice.min ||
                vehicleSearchFilters.askPrice.max !== prevState.vehicleSearchFilterValues.askPrice.max ||
                vehicleSearchFilters.mileage.min !== prevState.vehicleSearchFilterValues.mileage.min ||
                vehicleSearchFilters.mileage.max !== prevState.vehicleSearchFilterValues.mileage.max ||
                vehicleSearchFilters.year.min !== prevState.vehicleSearchFilterValues.year.min ||
                vehicleSearchFilters.year.max !== prevState.vehicleSearchFilterValues.year.max ||
                vehicleSearchFilters.selectedLocationIds.length !== prevState.vehicleSearchFilterValues.selectedLocationIds.length ||
                vehicleSearchFilters.isTowHitchSelected !== prevState.vehicleSearchFilterValues.isTowHitchSelected ||
                vehicleSearchFilters.isFuelDrivenAuxiliaryHeaterSelected !== prevState.vehicleSearchFilterValues.isFuelDrivenAuxiliaryHeaterSelected ||
                vehicleSearchFilters.isCruiseControlSelected !== prevState.vehicleSearchFilterValues.isCruiseControlSelected ||
                vehicleSearchFilters.isMetallicSelected !== prevState.vehicleSearchFilterValues.isMetallicSelected ||
                vehicleSearchFilters.co2.min !== prevState.vehicleSearchFilterValues.co2.min ||
                vehicleSearchFilters.co2.max !== prevState.vehicleSearchFilterValues.co2.max)
            {
                return {
                    vehicleSearchFilterValues: vehicleSearchFilters,
                    vehicleSearchResult: [],
                    vehicleSearchResultIndex: -1,
                    loading: true,
                    top: 30,
                    skip: 0
                };
            }
            else return null;
        }
        else return null;
        
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.vehicleSearchFilterValues.selectedModelNames.length !== this.state.vehicleSearchFilterValues.selectedModelNames.length ||
            prevState.vehicleSearchFilterValues.isBodyTypeSedanSelected !== this.state.vehicleSearchFilterValues.isBodyTypeSedanSelected ||
            prevState.vehicleSearchFilterValues.isBodyTypeFarmariSelected !== this.state.vehicleSearchFilterValues.isBodyTypeFarmariSelected ||
            prevState.vehicleSearchFilterValues.isBodyTypeHacthbackSelected !== this.state.vehicleSearchFilterValues.isBodyTypeHacthbackSelected ||
            prevState.vehicleSearchFilterValues.isBodyTypeSUVSelected !== this.state.vehicleSearchFilterValues.isBodyTypeSUVSelected ||
            prevState.vehicleSearchFilterValues.isFuelBensiiniSelected !== this.state.vehicleSearchFilterValues.isFuelBensiiniSelected ||
            prevState.vehicleSearchFilterValues.isFuelDieselSelected !== this.state.vehicleSearchFilterValues.isFuelDieselSelected ||
            prevState.vehicleSearchFilterValues.isFuelKaasuSelected !== this.state.vehicleSearchFilterValues.isFuelKaasuSelected ||
            prevState.vehicleSearchFilterValues.isFuelSahkoSelected !== this.state.vehicleSearchFilterValues.isFuelSahkoSelected ||
            prevState.vehicleSearchFilterValues.isFuelHybridiSelected !== this.state.vehicleSearchFilterValues.isFuelHybridiSelected ||
            prevState.vehicleSearchFilterValues.isTransmissionManualSelected !== this.state.vehicleSearchFilterValues.isTransmissionManualSelected ||
            prevState.vehicleSearchFilterValues.isTransmissionAutomaticSelected !== this.state.vehicleSearchFilterValues.isTransmissionAutomaticSelected ||
            prevState.vehicleSearchFilterValues.isFourWheelDriveSelected !== this.state.vehicleSearchFilterValues.isFourWheelDriveSelected ||
            prevState.vehicleSearchFilterValues.askPrice.min !== this.state.vehicleSearchFilterValues.askPrice.min ||
            prevState.vehicleSearchFilterValues.askPrice.max !== this.state.vehicleSearchFilterValues.askPrice.max ||
            prevState.vehicleSearchFilterValues.mileage.min !== this.state.vehicleSearchFilterValues.mileage.min ||
            prevState.vehicleSearchFilterValues.mileage.max !== this.state.vehicleSearchFilterValues.mileage.max ||
            prevState.vehicleSearchFilterValues.year.min !== this.state.vehicleSearchFilterValues.year.min ||
            prevState.vehicleSearchFilterValues.year.max !== this.state.vehicleSearchFilterValues.year.max ||
            prevState.vehicleSearchFilterValues.selectedLocationIds.length !== this.state.vehicleSearchFilterValues.selectedLocationIds.length ||
            prevState.vehicleSearchFilterValues.isTowHitchSelected !== this.state.vehicleSearchFilterValues.isTowHitchSelected ||
            prevState.vehicleSearchFilterValues.isFuelDrivenAuxiliaryHeaterSelected !== this.state.vehicleSearchFilterValues.isFuelDrivenAuxiliaryHeaterSelected ||
            prevState.vehicleSearchFilterValues.isCruiseControlSelected !== this.state.vehicleSearchFilterValues.isCruiseControlSelected ||
            prevState.vehicleSearchFilterValues.isMetallicSelected !== this.state.vehicleSearchFilterValues.isMetallicSelected ||
            prevState.vehicleSearchFilterValues.co2.min !== this.state.vehicleSearchFilterValues.co2.min ||
            prevState.vehicleSearchFilterValues.co2.max !== this.state.vehicleSearchFilterValues.co2.max)
        {
            this.populateVehicleData();
        }
    }

    componentDidMount(suppressScroll) {
        this.populateVehicleData();
        //if (!suppressScroll) {
        //    window.scrollTo(0, 750);
        //}
    }

    async populateVehicleData() {
        const response = await fetch('vehiclesearch', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Top: this.state.top,
                Skip: this.state.skip,
                ModelNames: this.state.vehicleSearchFilterValues.selectedModelNames,
                FuelIds: this.state.vehicleSearchFilterValues.selectedFuelIds,
                BodyIds: this.state.vehicleSearchFilterValues.selectedBodyTypeIds,
                TransmissionIds: this.state.vehicleSearchFilterValues.selectedTransmissionIds,
                DriveIds: this.state.vehicleSearchFilterValues.isFourWheelDriveSelected ? ["3"] : [],
                Accessories: this.state.vehicleSearchFilterValues.accessories,
                Locations: this.state.vehicleSearchFilterValues.selectedLocationIds,
                AskPriceFilterRange: { 
                    MinAskPrice: this.state.vehicleSearchFilterValues.askPrice.min,
                    MaxAskPrice: this.state.vehicleSearchFilterValues.askPrice.max
                },
                InTrafficDateFilterRange: {
                    MinInTrafficDate: new Date(this.state.vehicleSearchFilterValues.year.min + "-01-01"),
                    MaxInTrafficDate: new Date(this.state.vehicleSearchFilterValues.year.max + "-12-31")
                },
                KilometrageFilterRange: {
                    MinKilometrage: this.state.vehicleSearchFilterValues.mileage.min < 1000 ? 0 :
                        Math.floor(this.state.vehicleSearchFilterValues.mileage.min / 1000),
                    MaxKilometrage: Math.floor(this.state.vehicleSearchFilterValues.mileage.max/1000)
                },
                CO2FilterRange: {
                    MinCO2: this.state.vehicleSearchFilterValues.co2.min,
                    MaxCO2: this.state.vehicleSearchFilterValues.co2.max
                }
            }),
        });
        const data = await response.json();

        var temp = this.state.vehicleSearchResult.slice();
        temp.push(data)

        this.setState({
            vehicleSearchResult: temp,
            vehicleSearchResultIndex: this.state.vehicleSearchResultIndex + 1,
            loading: false
        });

        sessionStorage.clear();
        sessionStorage.setItem('session_vehicleSearchFilterValues', JSON.stringify(this.state.vehicleSearchFilterValues));
        sessionStorage.setItem('session_vehicleSearchResult', JSON.stringify(this.state.vehicleSearchResult));
    }
}

