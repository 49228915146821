import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Button, Collapse } from 'reactstrap';
import NumberFormat from 'react-number-format';
import GoogleTagManager from './GoogleTagManager';
import { VehicleSearchFilters } from './VehicleSearchFilters';
import { ResetFiltersButton } from './ResetFiltersButton';
import { PrimaryBannerButton } from './PrimaryBannerButton';

import '../custom.css';
import './Home.css';
import './Vehicle.css';
import './Content.css';

import smallSearch from '../images/icon-search.svg';
import arrowBackBig from '../images/arrow-back-big.svg';
import arrowBackSmall from '../images/arrow-back-small.svg';

// Fallback car images
import carImageCitigo from '../images/cars/citigo.png';
import carImageFabia from '../images/cars/fabia.png';
import carImageKaroq from '../images/cars/karoq.png';
import carImageKamiq from '../images/cars/kamiq.png';
import carImageKodiaq from '../images/cars/kodiaq.png';
import carImageOctavia from '../images/cars/octavia.png';
import carImageScala from '../images/cars/scala.png';
import carImageSuperb from '../images/cars/superb.png';
import carImageRapid from '../images/cars/rapid.png'

//import demoCar from '../images/demovehicle/skoda-superb-black-edition-black.jpg';

export class Content extends Component {
    static displayName = Content.name;

    constructor(props) {
        super(props);

        let vehicleSearchFilters = JSON.parse(sessionStorage.getItem('session_vehicleSearchFilterValues'));
        let vehicleSearchResult = JSON.parse(sessionStorage.getItem('session_vehicleSearchResult'));

        this.state = {
            vehicleSearchResult: vehicleSearchResult,
            vehicleSearchFilterValues: vehicleSearchFilters,
            isSearchFiltersOpen: false,
            dropdownOpenModelName: false,
            dropdownOpenLocation: false,
            setDropdownOpen: false,
            isMoreOpen: false,
            fallbackCarImages: {
                "Citigo": carImageCitigo,
                "Fabia": carImageFabia,
                "Karoq": carImageKaroq,
                "Kamiq": carImageKamiq,
                "Kodiaq": carImageKodiaq,
                "Octavia": carImageOctavia,
                "Scala": carImageScala,
                "Superb": carImageSuperb,
                "Rapid": carImageRapid
            }
        };

        this.toggleSearchFilters = this.toggleSearchFilters.bind(this);
    }

    toggleSearchFilters(event) {
        this.setState({
            isSearchFiltersOpen: !this.state.isSearchFiltersOpen
        });
    }

    renderVehicles(vehicleSearchResult) {
        let max = 0;
        if (vehicleSearchResult && (vehicleSearchResult.length > 0)) {
            max = vehicleSearchResult[0].totalResultCount < 6 ? vehicleSearchResult[0].totalResultCount : 6;
        }

        if (!max) {
            return "";
        }

        return (
            vehicleSearchResult[0].inventoryVehicles.slice(0, max).map((vehicle, i) =>
                <Col xs='12' sm='12' md='12' lg xl key={vehicle.id}>
                    <div className="search-result-car">
                        <Link to={{
                            pathname: "/vehicleinfo", search: "?id=" + vehicle.id,
                            state: {
                                vehicleSearchResult: this.state.vehicleSearchResult,
                                vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                            }
                        }}>
                            <img src={vehicle.images.marketingImageUrls.length ? vehicle.images.marketingImageUrls[0].replace(".jpg", "_220.jpg").replace(".JPG", "_220.JPG") : this.state.fallbackCarImages[vehicle.modelName]} alt={vehicle.makeName + ' rek.nro. ' + vehicle.formattedLicensePlateNumber} className="search-result-car-img other-vehicles-img" onError={(e) => { e.target.onerror = null; e.target.src = this.state.fallbackCarImages[vehicle.modelName] }} />
                            <p className="search-result-car-model">{vehicle.modelName} {vehicle.modelType}</p>
                            <p className="search-result-car-info">{vehicle.modelYear} | {vehicle.kilometrage} 000 km</p>
                            <p className="search-result-car-price">
                                <NumberFormat value={vehicle.askPrice} displayType={'text'} thousandSeparator={' '} /> &euro;</p>
                        </Link>
                    </div>
                </Col>
            ));
    }

    render() {

        let searchResultCount = 0;
        if (this.state.vehicleSearchResult && this.state.vehicleSearchResult.length > 0) {
            searchResultCount = this.state.vehicleSearchResult[0].totalResultCount;
        }

        let bannerClassName = "vehicle-page-banner";
        if (window.supportsWebp === false) {
            bannerClassName = "vehicle-page-banner-no-webp";
        }


        let othervehicles = this.renderVehicles(this.state.vehicleSearchResult);
        const event = { platform: 'react-stack' }
        return (
            <Container>
                {/*<GoogleTagManager gtmId='GTM-5KVJGP3' scriptId='gtm-script-container' dataLayerName='content' additionalEvents={event} previewVariables='' />*/}
                <div className={bannerClassName}>
                    <Row>
                        <Col xs='0' sm='0' md='0' lg='10' xl='10'>
                            <div className="vehicle-page-bannerTextArea">
                                <p className="bannerTitle">Loisto</p>
                                <p className="bannerText">Vaihtoautojen valioyksilöt</p>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col className="quick-search" xs='12' sm='12' md='12' lg='3'>
                        <div className='quick-search-heading-area'>
                            <Button id="btn-collapse-open-searchfilters"
                                className="quick-search-heading pt-4"
                                onClick={this.toggleSearchFilters}>
                                <img src={smallSearch} alt="Hae" />Pikahakukriteerit</Button>
                        </div>

                        <div className='quick-search-result pl-4 pt-2 pb-2'>
                            L&ouml;ytyi <strong>{searchResultCount}</strong> Loisto-autoa
                        </div>

                        <Route render={({ history }) => (
                            <ResetFiltersButton
                                history={history} />
                        )} />

                        <Collapse isOpen={this.state.isSearchFiltersOpen}>

                            <Route render={({ history }) => (
                                <VehicleSearchFilters
                                    history={history}
                                    currentVehicleSearchFilterValues={JSON.stringify(this.state.vehicleSearchFilterValues)} />
                            )} />

                            <Button id="btn-collapse-close-searchfilters"
                                className="search-form-btn-collapse-close mr-4"
                                onClick={this.toggleSearchFilters}>Sulje hakukriteerit</Button>
                        </Collapse>

                        <div className="d-none d-lg-block">
                            {othervehicles}
                            <div className="left-column-back ml-5 mt-5 mr-2 mb-2">
                                <Link to={{
                                    pathname: "/",
                                    state: {
                                        vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                    }
                                }}>
                                    <img src={arrowBackBig} alt="Back" className="mr-4 pt-2"></img>
                                    <span>Loisto-vaihtoautojen valioyksilöt</span>
                                </Link>
                            </div>
                        </div>
                    </Col>

                    <Col xs='0' sm='0' md='0' lg='9'>
                        <div className="content-area">
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                    <div className="content-area-back pt-4">
                                        <Link to={{
                                            pathname: "/",
                                            state: {
                                                vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                            }
                                        }}>
                                            <img src={arrowBackSmall} alt="Back" className="mr-2 mb-1"></img>
                                            Loisto - vaihtoautojen valioyksilöt
                                        </Link>
                                    </div>

                                    <h1>Loisto – vaihtoautojen valioyksilöt</h1>

                                    <div className="text-content mb-3">
                                        Loisto on Škodan uusi vaihtoauto-ohjelma, josta löydät parhaat ja valikoidut Škoda-vaihtoautot. Kaikki Loisto-ohjelmaan kuuluvat vaihtoautot ovat huolellisesti kuntotarkastettuja ja huoltokirjoiltaan aukottomia, alle viisivuotiaita ja maksimissaan 130&nbsp;000 km ajettuja valioyksilöitä.
                                    </div>
             
                                    <div className="text-content mb-3">
                                        Loisto-ohjelman vaihtoautoihin on hankittavissa Loisto-vaihtoautoturva, joka tuo huolettomuutta autonhankintaan. Loisto-vaihtoautoturva kattaa auton tekniset viat ja rikkoutumiset 1 vuoden tai 20&nbsp;000 km ajan ostohetkestä. Loisto-vaihtoautoturvassa on nerokasta sekin, että jos jostain syystä tulet toisiin aatoksiin uuden Loisto-vaihtoautosi kanssa, voit vaihtaa sen toiseen Škoda-merkkiseen autoon 14 vuorokauden tai 1000 km kuluttua auton ostohetkestä.
                                    </div>

                                    <div className="text-content mb-3">
                                        Loisto-vaihtoautoturvan saat ainoastaan valtuutetulta Škoda-jälleenmyyjältäsi.
                                    </div>

                                    <div className="text-content mb-3">
                                        Tutustu Škodan Loisto-vaihtoautovalikoimaan ja valitse omasi.
                                    </div>

                                    <h2 className="loisto-content-h2">Škodan Loisto-vaihtoautot pähkinänkuoressa</h2>

                                    <div className="text-content mb-3 loisto-briefly">
                                        <i>Alle 5-vuotiaita valioyksilöitä</i>
                                        <i>Mittarilukema alle 130&nbsp;000 km</i>
                                        <i>Merkkihuolloissa huollettuja ja kuntotarkastettuja</i>
                                        <i>Ajoturva voimassa</i>
                                        <i>Lisäpalveluna hankittavissa myös Loisto-vaihtoautoturva 12 kk/20&nbsp;000 km</i>
                                    </div>

                                    <h2 className="loisto-content-h2">Loisto-vaihtoautoturva tuo huolettomuutta autonhankintaan</h2>

                                    <div className="text-content mb-3">
                                        <h3 className="loisto-content-h3">Huolettomia ajokilometrejä</h3>
                                        Loisto-vaihtoautoturva kattaa auton teknisten vikojen ja rikkoutumisien korjauksen 1 vuoden tai 20&nbsp;000 km ajan auton ostohetkestä.
                                    </div>
                                    <div className="text-content mb-3">
                                        <h3 className="loisto-content-h3">Tarkastettu ja kunnostettu</h3>
                                        Loisto-vaihtoautot ovat Škoda Service -merkkihuolloissa huollettuja ja huolellisesti kuntotarkastettuja sekä huoltokirjaltaan aukottomia vaihtoautoja.
                                    </div>
                                    <div className="text-content mb-3">
                                        <h3 className="loisto-content-h3">Turva tien päälle</h3>
                                        Loisto-vaihtoautoissa on voimassa myös Škoda Ajoturva, joka tarjoaa 24 h tiepalvelun ja tarvittaessa autosi hinauksen sekä sinun ja matkaseurueesi kuljetuksen pois vikapaikalta, mikäli autosi kuntoon saattaminen vaatisikin korjaamotoimenpiteitä.
                                    </div>
                                    <div className="text-content mb-3">
                                        Teknisen vian tai rikkoutumisen sattuessa Škoda Ajoturva tarjoaa sinulle sijaisauton, mikäli autoasi ei saada saman päivän aikana kuntoon. Škoda Ajoturva on voimassa seuraavaan huoltoon asti ja on elinikäinen, kun huollatat autosi huolto-ohjelman mukaisesti Škoda Service -merkkihuollossa.
                                    </div>
                                    <div className="text-content mb-3">
                                        Lue lisää &Scaron;koda&nbsp;Ajoturvasta <a className="text-skoda-green" href="https://www.skoda.fi/omistaja/skoda-ajoturva" target="_blank">tästä</a>.
                                    </div>
                                    <div className="text-content mb-3">
                                        <h3 className="loisto-content-h3">Vaihto-oikeus</h3>
                                        Jos jostain syystä tuletkin toisiin aatoksiin uuden Loisto-vaihtoturvallisen autosi kanssa, sinulla on mahdollisuus vaihtaa autosi toiseen Škoda-merkkiseen vaihtoautoon 14 vuorokauden tai 1000 km kuluttua auton ostohetkestä.
                                    </div>
                                    <div className="text-content mb-3">
                                        <h3 className="loisto-content-h3">Kotiinkuljetus</h3>
                                        Halutessasi auto toimitetaan kaupanteon jälkeen suoraan kotiosoitteeseesi. Kysy lisää kotiinkuljetuksesta ja siihen liittyvistä kustannuksista Škoda-jälleenmyyjältäsi.
                                    </div>
                                    <div className="text-content mb-3">
                                        <br />
                                        Loisto-vaihtoautoturvan hinta on 490€.
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </Container>
        );
    }
}