import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Table } from 'reactstrap';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { Button, Collapse } from 'reactstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import NumberFormat from 'react-number-format';
import GoogleTagManager from './GoogleTagManager';
import { VehicleSearchFilters } from './VehicleSearchFilters';
import { ResetFiltersButton } from './ResetFiltersButton';
import { PrimaryBannerButton } from './PrimaryBannerButton';

import '../custom.css';
import './Home.css';
import './Vehicle.css';

import smallSearch from '../images/icon-search.svg';
import arrowBackBig from '../images/arrow-back-big.svg';
import arrowBackSmall from '../images/arrow-back-small.svg';

// Fallback car images
import carImageCitigo from '../images/cars/citigo.png';
import carImageFabia from '../images/cars/fabia.png';
import carImageKaroq from '../images/cars/karoq.png';
import carImageKamiq from '../images/cars/kamiq.png';
import carImageKodiaq from '../images/cars/kodiaq.png';
import carImageOctavia from '../images/cars/octavia.png';
import carImageScala from '../images/cars/scala.png';
import carImageSuperb from '../images/cars/superb.png';
import carImageRapid from '../images/cars/rapid.png'
import demoVehicleBigImage from '../images/demovehicle/auto-kuvat.png';

export class Vehicle extends Component {
    static displayName = Vehicle.name;

    constructor(props) {
        super(props);

        let vehicleSearchFilters = props.location.state !== undefined && props.location.state.vehicleSearchFilterValues !== undefined ?
            JSON.parse(props.location.state.vehicleSearchFilterValues) : null;

        if (this.props.history.location.search === "") {
            this.props.history.push({
                pathname: "/"
            });
        }

        this.state = {
            vehicleSearchFilterValues: vehicleSearchFilters,
            vehicleResult: null,
            loading: true,
            activeTab: '1',
            isMoreFeaturesOpen: false,
            isSearchFiltersOpen: false,
            dropdownOpenModelName: false,
            dropdownOpenLocation: false,
            setDropdownOpen: false,
            isMoreOpen: false,
            fallbackCarImages: {
                "Citigo": carImageCitigo,
                "Fabia": carImageFabia,
                "Karoq": carImageKaroq,
                "Kamiq": carImageKamiq,
                "Kodiaq": carImageKodiaq,
                "Octavia": carImageOctavia,
                "Scala": carImageScala,
                "Superb": carImageSuperb,
                "Rapid": carImageRapid
            }
        };

        this.toggleTabs = this.toggleTabs.bind(this);
        this.toggleMoreFeatures = this.toggleMoreFeatures.bind(this);
        this.showHideMoreFeaturesTitles = this.showHideMoreFeaturesTitles.bind(this);
        this.renderVehicle = this.renderVehicle.bind(this);
        this.toggleSearchFilters = this.toggleSearchFilters.bind(this);
    }

    toggleTabs(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleMoreFeatures(event) {
        this.setState({
            isMoreFeaturesOpen: !this.state.isMoreFeaturesOpen
        }, () => { this.showHideMoreFeaturesTitles() });
    }

    showHideMoreFeaturesTitles() {
        console.log("this.state.isMoreFeaturesOpen ", this.state.isMoreFeaturesOpen);
        var btn = document.getElementById("btn-collapse-features-open");
        if (this.state.isMoreFeaturesOpen) {
            btn.classList.add("hidden");
        }
        else {
            btn.classList.remove("hidden");
        }
    }

    toggleSearchFilters(event) {
        this.setState({
            isSearchFiltersOpen: !this.state.isSearchFiltersOpen
        });
    }

    renderVehicles(vehicleSearchResult) {
        let currentVehicleId = this.props.location.search.substring(4);
        let filteredStockVehicles = vehicleSearchResult[0].inventoryVehicles.filter(v => v.id !== currentVehicleId);
        let max = vehicleSearchResult[0].totalResultCount < 6 ? vehicleSearchResult[0].totalResultCount : 6;
        return (
            filteredStockVehicles.slice(0, max).map((vehicle, i) =>
                <Col xs='12' sm='12' md='12' lg xl key={vehicle.id}>
                    <div className="search-result-car pl-3 pr-3">
                        <Link to={{
                            pathname: "/vehicleinfo", search: "?id=" + vehicle.id,
                            state: {
                                vehicleSearchResult: this.props.location.state.vehicleSearchResult
                            }
                        }}>
                            <img src={vehicle.images.marketingImageUrls.length ? vehicle.images.marketingImageUrls[0].replace(".jpg", "_220.jpg").replace(".JPG", "_220.JPG") : this.state.fallbackCarImages[vehicle.modelName]} alt={vehicle.formattedLicensePlateNumber} className="search-result-car-img other-vehicles-img" onError={(e) => { e.target.onerror = null; e.target.src = this.state.fallbackCarImages[vehicle.modelName] }} />
                            <p className="search-result-car-model">{vehicle.modelName}</p>
                            <p className="search-result-car-info">{vehicle.modelYear} | {vehicle.kilometrage} 000 km</p>
                            <p className="search-result-car-price">
                                <NumberFormat value={vehicle.askPrice} displayType={'text'} thousandSeparator={' '} /> &euro;</p>
                        </Link>
                    </div>
                </Col>
            ));
    }

    renderVehicle(vehicle) {
        if (vehicle === null) {
            return (
                <Col xs='0' sm='0' md='0' lg='9'>
                    <div className="content-area">
                        <Row>
                            <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                <div className="content-area-back pt-4">
                                    <Link to={{
                                        pathname: "/",
                                        state: {
                                            vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                        }
                                    }}>
                                        <img src={arrowBackSmall} alt="Back" className="mr-2 mb-1"></img>
                                    Loisto - vaihtoautojen valioyksilöt
                                </Link>
                                </div>
                                <h2 className="mb-4">Autoa ei löytynyt, se on mahdollisesti jo poistunut myynnistä.</h2>
                            </Col>
                        </Row>
                    </div>
                </Col>
            );
        }

        console.log("renderVehicle with ", vehicle);

        const fuelIdArray = {
            0: 'Ei tiedossa', 1: 'Bensiini', 10: 'Hybridi diesel', 11: 'Plugin-hybridi bensiini', 12: 'Plugin-hybridi diesel',
            13: 'Bensiini MHVE', 14: 'Diesel MHVE', 15: 'Sähkö-bensiini REx', 16: 'Sähkö-diesel REx',
            2: 'Diesel', 3: 'Kaasu', 4: 'Sähkö', 5: 'Hybridi', 6: 'Bensiini-Kaasu', 7: 'Bensiini-Etanoli', 8: 'CNG Maakaasu', 9: 'Vety'};

        const gearboxArray = {
             1: 'Manuaalinen' ,  0: 'Puoliautomaatti' ,  3: 'Automaattinen portaaton' ,  2: 'Automaattinen' }
        ;

        const bodyTypeArray = {
            0: 'Ei tiedossa', 1: 'Sedan', 2: 'Monikäyttö', 20: 'Erillinen kuormatila',
            3: 'Viistoperä', 31: 'Custom/Chopper/Cruiser', 32: 'Enduro/Cross/All road/Off Road',
            33: 'Katu/Matka/Sport', 34: 'Kilpa-ajoneuvo', 35: 'Trial', 36: 'Trike', 37: 'Yhdistelmä/Sivuvaunu',
            4: 'Coupe', 5: 'Farmari', 6: 'Erikoisauto', 61: 'Avoauto', 7: 'Pakettiauto', 8: 'Maastoauto', 81: 'Pick-up', 9: 'Tila-auto'
        }

        const driveTypeArray = {
            1: 'Etuveto',
            2: 'Takaveto',
            3: 'Neliveto'
        }
        let driveType = driveTypeArray[vehicle.technicalInformation.driveType];

        let vehicleFuel = fuelIdArray[vehicle.fuelType];
        let transmission = gearboxArray[vehicle.technicalInformation.transmissionType];
        let bodyType = bodyTypeArray[vehicle.bodyType];

        let inTrafficDate = new Date(Date.parse(vehicle.inTrafficDate));
        let inTrafficYear = inTrafficDate.getFullYear();
        let inTrafficMonth = inTrafficDate.getMonth() +1;
        let inTraffidDateStr = inTrafficDate.getDate() + '.' + inTrafficMonth + '.' + inTrafficYear;

        let kWPower = vehicle.technicalInformation.maxPower;
        let hpPower = printHpPower(kWPower);

        let inspectionDate = new Date(Date.parse(vehicle.inspectionDate));
        let inspectionMonth = inspectionDate.getMonth() + 1;
        let inspectionDateStr = inspectionDate.getDate() + '.' + inspectionMonth + '.' + inspectionDate.getFullYear();
        if (inspectionDateStr === '1.1.1') {
            inspectionDateStr = "-";
        }

        let accessoriesArray = vehicle.accessories;
        let resultToRender = [];

        // 0 - Package
        //const packageAccessoriesArray = accessoriesArray.filter(a =>
        //    a.type === 0
        //);
        //if (packageAccessoriesArray.length !== 0) {
        //    resultToRender.push({ sectionTitle: "Paketti", accessories: packageAccessoriesArray});
        //}

        // 1 - Custom  standard accessories
        const customStandardAccessoriesArray = accessoriesArray.filter(a =>
            a.type === 0 || a.type === 1 || a.type === 7
        );
        if (customStandardAccessoriesArray.length !== 0) {
            resultToRender.push({ sectionTitle: "Muut varusteet ja lisätiedot autosta", accessories: customStandardAccessoriesArray });
        }

        // 2 - Custom  accessories
        const customAccessoriesArray = accessoriesArray.filter(a =>
            a.type === 2
        );
        if (customAccessoriesArray.length !== 0) {
            resultToRender.push({ sectionTitle: "Custom  accessories (FI)", accessories: customAccessoriesArray });
        }

        // 3 - New car standard accessories
        const newCarAccessoriesArray = accessoriesArray.filter(a =>
            a.type === 3
        );
        if (newCarAccessoriesArray.length !== 0) {
            resultToRender.push({ sectionTitle: "Vakiovarusteet", accessories: newCarAccessoriesArray });
        }

        // 4 - New car additional accessories
        const newCarAdditionalAccessoriesArray = accessoriesArray.filter(a =>
            a.type === 4
        );
        if (newCarAdditionalAccessoriesArray.length !== 0) {
            resultToRender.push({ sectionTitle: "Tehdasasenteiset lisävarusteet", accessories: newCarAdditionalAccessoriesArray });
        }

        // 5 - Ordered accessories
        const orderedAccessoriesArray = accessoriesArray.filter(a =>
            a.type === 5
        );
        if (orderedAccessoriesArray.length !== 0) {
            resultToRender.push({ sectionTitle: "Tilatut lisävarusteet", accessories: orderedAccessoriesArray });
        }

        // 6 - Unknown
        const unknownAccessoriesArray = accessoriesArray.filter(a =>
            a.type === 6
        );
        if (unknownAccessoriesArray.length !== 0) {
            resultToRender.push({ sectionTitle: "Ei-määritellyt lisävarusteet", accessories: unknownAccessoriesArray });
        }

        //// 7 - Campaign
        //const campaignAccessoriesArray = accessoriesArray.filter(a =>
        //    a.type === 7
        //);
        //if (campaignAccessoriesArray.length !== 0) {
        //    resultToRender.push({ sectionTitle: "Kampanjavarusteet", accessories: campaignAccessoriesArray });
        //}

        // 8 - From an external source
        const externalSourceAccessoriesArray = accessoriesArray.filter(a =>
            a.type === 8
        );
        if (externalSourceAccessoriesArray.length !== 0) {
            resultToRender.push({ sectionTitle: "Muut lisävarusteet", accessories: externalSourceAccessoriesArray });
        }

        let vehicleImages = vehicle.images.marketingImageUrls.slice().map((imageUrl, i) =>
            <div key={i}>
                <img className="carousel-thumb-image" src={imageUrl.indexOf('_800.') !== -1 ? imageUrl.replace('_800.', '_220.') : imageUrl}></img>
                <a target="_blank" rel="noreferrer" href={imageUrl.indexOf('_800.') !== -1 ? imageUrl.replace('_800.', '.') : imageUrl} alt={"Kuva " + (i + 1) + ": " + vehicle.modelName + " rek.nro. " + vehicle.formattedLicensePlateNumber}>
                    <img src={imageUrl} alt={"Kuva " + (i + 1) + ": " + vehicle.modelName + " rek.nro. " + vehicle.formattedLicensePlateNumber} onError={(e) => { e.target.onerror = null; e.target.src = this.state.fallbackCarImages[vehicle.modelName] }}></img>
                </a>
            </div>
        );
        function AccessoriesListNested(props) {
            const accessories = props.accessories;

            const listItems = accessories.accessories.map((acc, idx) =>
                <ListGroupItem key={acc.identifier + '-' + idx} >{acc.name}</ListGroupItem>
            );
            return (
                <Col xs='12' sm='12' md='12' lg={props.columnSize} xl={props.columnSize}>
                    <ListGroup>
                        <ListGroupItem active>
                        <ListGroupItemHeading>{accessories.sectionTitle}</ListGroupItemHeading>
                        </ListGroupItem>
                        {listItems}
                    </ListGroup>
                </Col>
            );
        }

        function AccessoriesList(props) {
            const accessories = props.accessories;
            const rootPosition = props.position;
            let position = props.position;

            let columnSize = accessories.length === 1 ? "12" : accessories.length === 2 ? "6" : "4"; 

            let nestedAccessoriesList = [];
            let rootAccessories;

            if (accessories === undefined || accessories.length === 0) {
                return;
            }

            if (accessories.length > 1) {

                for (var i = position + 1; i < accessories.length; i++) {
                    position = i;

                    if (position % 3 === 0) {
                        break;
                    } 

                    let nestedAccessories = <AccessoriesListNested key={position} accessories={accessories[position]} columnSize={columnSize} />;

                    nestedAccessoriesList.push(nestedAccessories);
                }
            } else {
                columnSize = 12;
            }

            if (position > 1 && position < accessories.length - 1) {
                rootAccessories = <AccessoriesList key={position} accessories={accessories} position={position} />;
            }

            const listItems = accessories[rootPosition].accessories.map((acc, idx) => 
                <ListGroupItem key={acc.identifier + '-' + idx}>{acc.name}</ListGroupItem>
            );
            return (
                <>
                        <Row>
                            <Col xs='12' sm='12' md='12' lg={columnSize} xl={columnSize}>
                                <ListGroup>
                                    <ListGroupItem active>
                                        <ListGroupItemHeading>{resultToRender[rootPosition].sectionTitle}</ListGroupItemHeading>
                                    </ListGroupItem>
                                    {listItems}
                                </ListGroup>
                            </Col>
                        {nestedAccessoriesList}
                        </Row>
                        {rootAccessories}
                </>
            );
        }

        function printEngineVol(engineVol) {
            if (engineVol === 0) {
                return '';
            }
            if (engineVol === 999) {
                return "1.0";
            }

            var volume = engineVol.toString().substring(0, 1) + "." + engineVol.toString().substring(1);
            const input = parseFloat(volume);
            if (Number.isNaN(input)) {
                return '';
            }
            const rounded = input.toFixed(1);
            return rounded.toString();
        }

        function printHpPower(kWPower) {
            if (kWPower === 0) {
                return '';
            }

            const input = parseFloat(kWPower);
            if (Number.isNaN(input)) {
                return '';
            }
            const rounded = (input * 1.36).toFixed(0);
            return rounded.toString();
        }

        return (
            <Col xs='0' sm='0' md='0' lg='9'>
                <div className="content-area">
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                            <div className="content-area-back pt-4">
                                <Link to={{
                                    pathname: "/",
                                    state: {
                                        vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                    }
                                }}>
                                    <img src={arrowBackSmall} alt="Back" className="mr-2 mb-1"></img>
                                    Loisto - vaihtoautojen valioyksilöt
                                </Link>
                            </div>
                            <h1>{vehicle.modelName} {vehicle.modelType.substring(0, vehicle.modelType.indexOfRegex('#|(\\d)'))}
                                <span className='pl-lg-5 pl-xl-5'>
                                    <NumberFormat value={vehicle.askPrice} displayType={'text'} thousandSeparator={' '} />
                                     &euro;</span>
                            </h1>
                            <h2 className="mb-4">{vehicle.modelType}</h2>
                            <div className="text-content mb-3">
                                {vehicle.marketingText}
                            </div>
                        </Col>
                    </Row>

                    {vehicleImages.length > 0 &&
                        <Row>
                            <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                <Carousel>
                                    {vehicleImages}
                                </Carousel>
                            </Col>
                        </Row>
                    }

                    <div className="vehicle-tabs-area mt-4">
                        <Nav tabs className="mb-4">
                            <NavItem className="mr-1 mr-sm-4 mr-md-4 mr-lg-4 mr-xl-4">
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTabs('1'); }}>
                                    <span>
                                        <span>Ominaisuudet</span>
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTabs('2'); }}>
                                    <span>
                                        <span>Varustelu</span>
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col xs='12' sm='12' md='12' lg='6'>
                                        <Table className="feature-table">
                                            <tbody>
                                                <tr><td>K&auml;ytt&ouml;voima</td>
                                                    <td className="feature-value">{vehicleFuel}</td></tr>
                                                <tr><td>Vaihteisto</td>
                                                    <td className="feature-value">{transmission}</td></tr>
                                                <tr><td>Rekisterinumero</td>
                                                    <td className="feature-value">{vehicle.formattedLicensePlateNumber}</td></tr>
                                                <tr><td>Korimalli</td>
                                                <td className="feature-value">{bodyType}</td></tr>
                                                <tr><td>K&auml;ytt&ouml;&ouml;nottovuosi</td>
                                                    <td className="feature-value">{inTrafficYear}</td></tr>
                                                <tr><td>Mittarilukema</td>
                                                    <td className="feature-value">{vehicle.kilometrage} 000</td></tr>
                                                <tr><td>Vetotapa</td>
                                                    <td className="feature-value">{driveType}</td></tr>
                                                <tr><td>Sijainti</td>
                                                    <td className="feature-value">{vehicle.sellerCompanyName}</td></tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='6'>
                                        <Table className="feature-table">
                                            <tbody>
                                                <tr><td>Teho</td>
                                                    <td className="feature-value">{kWPower} kW ({hpPower.toLocaleString('fi')} hv)</td></tr>
                                                <tr><td>Kulutus l/100 km</td>
                                                    <td className="feature-value"></td></tr>
                                                <tr><td>- kaupunki</td>
                                                    <td className="feature-value">{vehicle.fuelEconomy.city.toFixed(1)} l/100km</td></tr>
                                                <tr><td>- maantie</td>
                                                    <td className="feature-value">{vehicle.fuelEconomy.road.toFixed(1)} l/100 km</td></tr>
                                                <tr><td>- yhdistetty</td>
                                                    <td className="feature-value">{vehicle.fuelEconomy.combined.toFixed(1)} l/100 km</td></tr>
                                                <tr><td>V&auml;ri</td>
                                                    <td className="feature-value">{vehicle.colorText.fi}</td></tr>
                                                <tr><td>P&auml;&auml;st&ouml;t (CO2) g/km</td>
                                                    <td className="feature-value">{vehicle.cO2} g/km</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                                <button id="btn-collapse-features-open"
                                    className="features-btn-collapse"
                                    onClick={this.toggleMoreFeatures}>Lis&auml;&auml; ominaisuuksia</button>
                                <Collapse isOpen={this.state.isMoreFeaturesOpen}>
                                    <Row>
                                        <Col xs='12' sm='12' md='12' lg='6'>
                                            <Table className="feature-table">
                                                <tbody>
                                                    <tr><td>Moottorin tilavuus</td>
                                                        <td className="feature-value">{printEngineVol(vehicle.technicalInformation.engineVol)} L</td></tr>
                                                    <tr><td>Henkil&ouml;m&auml;&auml;r&auml;</td>
                                                        <td className="feature-value">{vehicle.technicalInformation.seatCount}</td></tr>
                                                    <tr><td>Ovien lukum&auml;&auml;r&auml;</td>
                                                        <td className="feature-value">{vehicle.doorAmount}</td>
                                                    </tr>
                                                    <tr><td>Viimeisin katsastus</td>
                                                        <td className="feature-value">{inspectionDateStr}</td></tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col xs='12' sm='12' md='12' lg='6'>
                                            <Table className="feature-table">
                                                <tbody>
                                                    <tr><td>Omamassa</td>
                                                        <td className="feature-value">{vehicle.technicalInformation.curbWeight} kg</td></tr>
                                                    <tr><td>K&auml;ytt&ouml;&ouml;nottop&auml;iv&auml;</td>
                                                        <td className="feature-value">{inTraffidDateStr}</td></tr>
                                                    <tr><td>Vetomassa (jarrullinen)</td>
                                                        <td className="feature-value">{vehicle.technicalInformation.towingCapacityWithBrakes} kg</td></tr>
                                                    <tr><td>Vetomassa (ei jarruja)</td>
                                                        <td className="feature-value">{vehicle.technicalInformation.towingCapacityWithoutBrakes} kg</td></tr>
                                                    <tr><td>Kiihtyvyys 0-100 km/h</td>
                                                        <td className="feature-value">{vehicle.technicalInformation.acceleration.toFixed(1)} s</td></tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <button id="btn-collapse-features-close"
                                        className="features-btn-collapse-close"
                                        onClick={this.toggleMoreFeatures}>V&auml;hemm&auml;n ominaisuuksia</button>
                                </Collapse>
                            </TabPane>
                            <TabPane tabId="2">
                                <AccessoriesList accessories={resultToRender} position={0} />
                            </TabPane>
                        </TabContent>
                    </div>

                    {/*<Row>
                        <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                            <div className="text-content mb-3">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore,
                                    </div>

                            <div className="text-content mb-3">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                    </div>
                        </Col>
                    </Row>*/}

                    <Row>
                        <Col xs='12' sm='12' md='12' lg='6' xl='3'>
                            <Link className="skoda-btn btn-primary" to={{
                                pathname: "/contact",
                                state: {
                                    vehicleResult: JSON.stringify(this.state.vehicleResult)
                                }
                            }}>
                                Ota yhteyttä
                            </Link>
                        </Col>
                        {/*<Col xs='12' sm='12' md='12' lg='4' xl='3' className="text-center">
                            <button className="btn btn-primary btn-vehicle-page-white">LIS&Auml;&Auml; VERTAILUUN</button>
                        </Col>
                        <Col xs='12' sm='12' md='12' lg='4' xl='3' className="text-center">
                            <button className="btn btn-primary btn-vehicle-page-white">RAHOITUSLASKURI</button>
                        </Col>
                        */}
                    </Row>

                    <Row className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
                        <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                            <div className="content-area-back pt-4">
                                <Link to={{
                                    pathname: "/",
                                    state: {
                                        vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                    }
                                }}>
                                    <img src={arrowBackSmall} alt="Back" className="mr-2 mb-1"></img>
                                    Loisto - vaihtoautojen valioyksilöt
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        );

    }

    render() {

        let contents = this.state.loading ?
            <Col xs='0' sm='0' md='0' lg='9' xl='10'>
                <div className="content-area">
                    <div className="loading" key="loading">
                        <Spinner animation="border" role="status">
                        </Spinner>
                    </div>
                </div>
            </Col> : 
            this.renderVehicle(this.state.vehicleResult);

        let searchResultCount = this.props.location.state !== undefined && this.props.location.state.vehicleSearchResult != null ?
            this.props.location.state.vehicleSearchResult[0].totalResultCount : 0;

        let othervehicles = this.props.location.state !== undefined && this.props.location.state.vehicleSearchResult ? this.renderVehicles(this.props.location.state.vehicleSearchResult) : null;

        let bannerClassName = "vehicle-page-banner";
        if (window.supportsWebp === false) {
            bannerClassName = "vehicle-page-banner-no-webp";
        }
        const event = { platform: 'react-stack' }
        return (
            <Container>
                {/*<GoogleTagManager gtmId='GTM-5KVJGP3' scriptId='gtm-script-container' dataLayerName='vehicle' additionalEvents={event} previewVariables='' />*/}
                <div className={bannerClassName}>
                    <Row>
                        <Col xs='0' sm='0' md='0' lg='10' xl='10'>
                            <div className="vehicle-page-bannerTextArea">
                                <p className="bannerTitle">Loisto</p>
                                <p className="bannerText">Vaihtoautojen valioyksilöt</p>
                                <Route render={({ history }) => (
                                    <PrimaryBannerButton
                                        history={history} contentPagePath="/content" contentPageTitle="Lue lisää" />
                                )} />
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col className='quick-search' xs='12' sm='12' md='12' lg='3'>
                        <div className='quick-search-heading-area'>
                            <Button id="btn-collapse-open-searchfilters"
                                className="quick-search-heading pt-4"
                                onClick={this.toggleSearchFilters}>
                                <img src={smallSearch} alt="Hae" />Pikahakukriteerit</Button>
                        </div>

                        <div className='quick-search-result pl-4 pt-2 pb-2'>
                            L&ouml;ytyi <strong>{searchResultCount}</strong> Loisto-autoa
                        </div>
                        <Route render={({ history }) => (
                            <ResetFiltersButton
                                history={history} />
                        )} />
                            <Collapse isOpen={this.state.isSearchFiltersOpen}>

                            <Route render={({ history }) => (
                                <VehicleSearchFilters
                                    history={history}
                                    currentVehicleSearchFilterValues={JSON.stringify(this.state.vehicleSearchFilterValues)} />
                            )} />

                            <Button id="btn-collapse-close-searchfilters"
                                    className="search-form-btn-collapse-close mr-4"
                                onClick={this.toggleSearchFilters}>Sulje hakukriteerit</Button>
                            </Collapse>

                        <div className="d-none d-lg-block">

                            {othervehicles}

                            <div className="left-column-back ml-5 mt-5 mr-2 mb-2">
                                <Link to={{
                                    pathname: "/",
                                    state: {
                                        vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                    }
                                }}>
                                <img src={arrowBackBig} alt="Back" className="mr-4 pt-2"></img>
                                <span>Loisto - vaihtoautojen valioyksilöt</span>
                            </Link>
                            </div>
                        </div>
                    </Col>
       
                    {contents}

                </Row>

            </Container>
        );
    }

    componentWillReceiveProps() {
        this.setState({
            loading: true
        }, () => {this.componentDidMount() }
        );
    }

    componentDidMount() {
        if (this.props.location.search !== "") {
            this.populateVehicleData();
            window.scrollTo(0, 700);
        }
    }

    async populateVehicleData() {
        const vehicleId = this.props.location.search;
        const response = await fetch('vehicle' + vehicleId);

        if (response.status === 200) {
            const data = await response.json();

            this.setState({
                vehicleResult: data,
                loading: false,
                activeTab: '1',
                isMoreFeaturesOpen: false
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }
}