import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Container } from 'reactstrap';

import '../custom.css';

export class PrimaryBannerButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contentPagePath: this.props.contentPagePath,
            contentPageTitle: this.props.contentPageTitle
        };

        this.navigateToContentPage = this.navigateToContentPage.bind(this);
    }

    navigateToContentPage(event) {
        this.props.history.push({
            pathname: this.state.contentPagePath
        });
    }

    render() {
        return (
            <Container>
                <button className="skoda-btn btn-primary" type="button" onClick={this.navigateToContentPage}>{this.state.contentPageTitle}</button>
            </Container>
    )
}
}