import React, { Component } from 'react';
import gtmParts from 'react-google-tag-manager';
import PropTypes from 'prop-types';

// inside the render method where you want to include the tag manager
//<GoogleTagManager gtmId='GTM-P79T557' />

// or with all optional parameters
// const event = { platform: 'react-stack' }
// < GoogleTagManager gtmId = 'GTM-12345' scriptId = 'gtm-script-container' dataLayerName = 'dl-backup' additionalEvents = { event } previewVariables = '' scheme = 'https:' />

class GoogleTagManager extends Component {
    componentDidMount() {
        const dataLayerName = this.props.dataLayerName || 'dataLayer';
        const scriptId = this.props.scriptId || 'react-google-tag-manager-gtm';

        if (!window[dataLayerName]) {
            const gtmScriptNode = document.getElementById(scriptId);

            eval(gtmScriptNode.textContent);
        }
    }

    render() {
        const gtm = gtmParts({
            id: this.props.gtmId,
            dataLayerName: this.props.dataLayerName || 'dataLayer',
            additionalEvents: this.props.additionalEvents || {},
            previewVariables: this.props.previewVariables || false,
            scheme: this.props.scheme || 'https:',
        });

        return (
            <div>
                <div>{gtm.noScriptAsReact()}</div>
                <div id={this.props.scriptId || 'react-google-tag-manager-gtm'}>
                    {gtm.scriptAsReact()}
                </div>
            </div>
        );
    }
}

GoogleTagManager.propTypes = {
    gtmId: PropTypes.string.isRequired,
    dataLayerName: PropTypes.string,
    additionalEvents: PropTypes.object,
    previewVariables: PropTypes.string,
    scriptId: PropTypes.string,
    scheme: PropTypes.string,
};

export default GoogleTagManager;
