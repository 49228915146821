import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../custom.css';
import './FooterLinks.css';

export class FooterLinks extends Component {
    static displayName = FooterLinks.name;

    render() {
        return (
                <div className="footerLinks">
                    <Row>
                    <Col xs='12' sm='12' md='12' lg='3' xl='3'></Col>
                    <Col xs='12' sm='12' md='12' lg='3' xl='3'>
                        <ul className="pl-0">
                            <li className='footerlink-heading'>Tiedot</li>
                            <li className='footerlink-item'><a href='https://clg.skoda-auto.com/api/Link?tool=Ownersmanuals&amp;culture=fi-fi&amp;target=home&amp;bid=443&amp;instancename=FIN&amp;salesprogram=FIN' className='footer-link' target='_blank' rel='noreferrer'>Käyttöohjeet</a></li>
                            <li className='footerlink-item'><a href='https://www.skoda.fi/skoda/skoda-connect' target='_blank' rel='noreferrer'>&Scaron;koda Connect</a></li>
                        </ul>
                    </Col>
                    <Col xs='12' sm='12' md='12' lg='3' xl='3'>
                        <ul className="pl-0">
                            <li className='footerlink-heading'>Ota yhteyttä</li>
                            <li className='footerlink-item'><a href='https://www.skoda.fi/skoda/maahantuoja' target='_blank' rel='noreferrer'>Maahantuoja</a></li>
                            <li className='footerlink-item'><a href='https://www.skoda.fi/muut/palaute' target='_blank' rel='noreferrer'>Palaute</a></li>
                            <li className='footerlink-item'><a href='https://retailers.skoda-auto.com/443/fi-fi' target='_blank' rel='noreferrer'>Palvelupisteet</a></li>
                        </ul>
                    </Col>
                    <Col xs='12' sm='12' md='12' lg='3' xl='3'>
                        <ul className="pl-0">
                            <li className='footerlink-heading'>Muut</li>
                            <li className='footerlink-item'><a href='https://www.skoda.fi/skoda/copyright' target='_blank' rel='noreferrer'>Copyright</a></li>
                            <li className='footerlink-item'><a href='https://www.skoda.fi/skoda/tietosuoja' target='_blank' rel='noreferrer'>Tietosuoja</a></li>
                        </ul>
                    </Col>
                    </Row>
                </div>
        );
    }
}