import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Container } from 'reactstrap';

import '../custom.css';

export class ResetFiltersButton extends Component {

    constructor(props) {
        super(props);

        this.resetSearchFilters = this.resetSearchFilters.bind(this);
    }

    resetSearchFilters(event) {
        this.props.history.push({
            pathname: "/reloadfiltersblock"
        });
    }

    render() {
        return (
            <Container>
                <div className='quick-search-result pl-4 pt-2 pb-2'>
                    <button id="btn-reset-filters" className="skoda-btn btn-ghost" onClick={this.resetSearchFilters}>Tyhjenn&auml; valinnat</button>
                </div>
            </Container>
        )
    }
}