import React, { Component } from 'react';

import { Button, Collapse } from 'reactstrap';
import { Form, FormGroup, Label, CustomInput } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Card, CardBody, CardText, CardGroup, CardTitle } from 'reactstrap';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import '../custom.css';
import './VehicleSearchFilters.css';

export class VehicleSearchFilters extends Component {
    static displayName = VehicleSearchFilters.name;

    constructor(props) {
        super(props);

        let vehicleSearchFilters = props.currentVehicleSearchFilterValues !== undefined && props.currentVehicleSearchFilterValues !== null ?
            JSON.parse(props.currentVehicleSearchFilterValues) : null;

        if (vehicleSearchFilters === null) {
            vehicleSearchFilters = JSON.parse(sessionStorage.getItem('session_vehicleSearchFilterValues'));
        }

        this.state = {
            vehicleSearchFilterValues: {
                selectedModelNames: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedModelNames : [],
                isBodyTypeSedanSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeSedanSelected : false,
                isBodyTypeFarmariSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeFarmariSelected : false,
                isBodyTypeHacthbackSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeHacthbackSelected : false,
                isBodyTypeSUVSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isBodyTypeSUVSelected : false,
                selectedBodyTypeIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedBodyTypeIds : [],
                isFuelBensiiniSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelBensiiniSelected : false,
                isFuelDieselSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelDieselSelected : false,
                isFuelKaasuSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelKaasuSelected : false,
                isFuelSahkoSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelSahkoSelected : false,
                isFuelHybridiSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelHybridiSelected : false,
                selectedFuelIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedFuelIds : [],
                isTransmissionManualSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isTransmissionManualSelected : false,
                isTransmissionAutomaticSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isTransmissionAutomaticSelected : false,
                selectedTransmissionIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.selectedTransmissionIds : [],
                isFourWheelDriveSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFourWheelDriveSelected : false,
                askPrice: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.askPrice.min : 0,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.askPrice.max : 80000
                },
                mileage: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.mileage.min : 0,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.mileage.max : 130000
                },
                year: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.year.min : (new Date().getFullYear()) - 5,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.year.max : new Date().getFullYear()
                },
                locations: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.locations : [],
                selectedLocationIds: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null && vehicleSearchFilters.selectedLocationIds !== undefined ? vehicleSearchFilters.selectedLocationIds : [],
                isTowHitchSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isTowHitchSelected : false,
                isFuelDrivenAuxiliaryHeaterSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isFuelDrivenAuxiliaryHeaterSelected : false,
                isCruiseControlSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isCruiseControlSelected : false,
                isMetallicSelected: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.isMetallicSelected : false,
                accessories: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.accessories : [],
                co2: {
                    min: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.co2.min : -1,
                    max: vehicleSearchFilters !== undefined && vehicleSearchFilters !== null ? vehicleSearchFilters.co2.max : 220
                }
            },
            isMoreOpen: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleModelName = this.toggleModelName.bind(this);
        this.toggleLocation = this.toggleLocation.bind(this);
        this.handleSelectedModelClick = this.handleSelectedModelClick.bind(this);
        this.handleSelectedLocationClick = this.handleSelectedLocationClick.bind(this);
        this.toggleMore = this.toggleMore.bind(this);
        this.showHideMoreTitles = this.showHideMoreTitles.bind(this);
        this.linkToNewSearch = this.linkToNewSearch.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const checked = target.type === 'checkbox' ? target.checked : false;
        const name = target.name;
        const value = target.value;
        const SUV = "isBodyTypeSUVSelected";
        let name2 = '';
        var temp = [];
        var filtered = [];

        if (name === 'isBodyTypeSedanSelected' || name === 'isBodyTypeFarmariSelected' || name === 'isBodyTypeHacthbackSelected' || name === SUV) {
            name2 = 'selectedBodyTypeIds';
            temp = this.state.vehicleSearchFilterValues.selectedBodyTypeIds.slice();
            if (checked) {
                if (name === SUV) {
                    var doubleValue = JSON.parse(target.value);
                    temp.push(doubleValue.first, doubleValue.second) // SUV bodyTypeId can be both '7' and '8'
                } else {
                    temp.push(value)
                }
            }
            else {
                if (name === SUV) {
                    var doubleValue = JSON.parse(target.value);
                    const second = doubleValue.second;
                    const first = doubleValue.first;

                    filtered = temp.filter(function (first, index, arr) {
                        return first !== doubleValue.first;
                    });
                    temp = filtered.slice(0);

                    filtered = temp.filter(function (second, index, arr) {
                        return second !== doubleValue.second;
                    });
                    temp = filtered.slice(0);

                } else {
                    filtered = temp.filter(function (value, index, arr) {
                        return value !== target.value;
                    });
                    temp = filtered.slice(0);
                }     
            }
        }

        if (name === 'isFuelBensiiniSelected' || name === 'isFuelDieselSelected' || name === 'isFuelKaasuSelected'
            || name === 'isFuelSahkoSelected' || name === 'isFuelHybridiSelected') {
            name2 = 'selectedFuelIds';
            temp = this.state.vehicleSearchFilterValues.selectedFuelIds.slice();
            if (checked) {
                temp.push(value)
            }
            else {
                filtered = temp.filter(function (value, index, arr) {
                    return value !== target.value;
                });
                temp = filtered.slice(0);
            }
        }

        if (name === 'isTransmissionManualSelected' || name === 'isTransmissionAutomaticSelected') {
            name2 = 'selectedTransmissionIds';
            temp = this.state.vehicleSearchFilterValues.selectedTransmissionIds.slice();
            if (checked) {
                temp.push(value)
            }
            else {
                filtered = temp.filter(function (value, index, arr) {
                    return value !== target.value;
                });
                temp = filtered.slice(0);
            }
        }

        if (name === 'isFuelDrivenAuxiliaryHeaterSelected' || name === 'isCruiseControlSelected' || name === 'isTowHitchSelected' || name === 'isMetallicSelected') {
            name2 = 'accessories';
            temp = this.state.vehicleSearchFilterValues.accessories.slice();
            if (checked) {
                temp.push(value)
            }
            else {
                filtered = temp.filter(function (value, index, arr) {
                    return value !== target.value;
                });
                temp = filtered.slice(0);
            }
        }

        var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues }
        vehicleSearchFilterValues[name] = checked;
        vehicleSearchFilterValues[name2] = temp;
        this.setState({
            vehicleSearchFilterValues
        }, () => { this.linkToNewSearch(); });
    }

    toggleModelName(event) {
        this.setState({
            dropdownOpenModelName: !this.state.dropdownOpenModelName
        });

        const target = event.target;
        const value = target.textContent;
        if (value !== 'Valitse') {
            var temp = this.state.vehicleSearchFilterValues.selectedModelNames.slice();
            if (!temp.includes(value)) {
                temp.push(value)
            }
            var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues }
            vehicleSearchFilterValues.selectedModelNames = temp;
            this.setState({
                vehicleSearchFilterValues
            }, () => { this.linkToNewSearch(); });
        }
    }

    handleSelectedModelClick(event) {
        const target = event.target;
        console.log("target", target);
        var temp = this.state.vehicleSearchFilterValues.selectedModelNames.slice();

        var filtered = temp.filter(function (value, index, arr) {
            return value !== event;
        });

        var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues }
        vehicleSearchFilterValues.selectedModelNames = filtered;
        this.setState({
            vehicleSearchFilterValues
        }, () => { this.linkToNewSearch(); });
    }

    toggleLocation(event) {
        this.setState({
            dropdownOpenLocation: !this.state.dropdownOpenLocation
        });

        const target = event.target;
        const selectedLocation = target.textContent;
        const index = target.value;
        if (selectedLocation !== 'Valitse') {
            var temp = this.state.vehicleSearchFilterValues.locations.slice();
            var temp2 = this.state.vehicleSearchFilterValues.selectedLocationIds.slice();
            var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues }

            var that = this;

            setTimeout(function() {
                temp[index].selected = true;
                temp2.push.apply(temp2, temp[index].locationIds);

                vehicleSearchFilterValues.locations = temp;
                vehicleSearchFilterValues.selectedLocationIds = temp2;

                that.setState({
                    vehicleSearchFilterValues
                }, () => { that.linkToNewSearch(); });
            }, 100);
        }
    }

    handleSelectedLocationClick(event) {
        var temp = this.state.vehicleSearchFilterValues.locations.slice();

        var index = 0;
        this.state.vehicleSearchFilterValues.locations.map((item, j) => {
            if (item.location === event) {
                index = j;
            }
        });
        temp[index].selected = false;

        var temp2 = this.state.vehicleSearchFilterValues.selectedLocationIds.filter((item) => {
            if (item !== event) {
                return item;
            }
        });

        var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues }

        vehicleSearchFilterValues.locations = temp;
        vehicleSearchFilterValues.selectedLocationIds = temp2;

        this.setState({
            vehicleSearchFilterValues
        }, () => { this.linkToNewSearch(); });
    }

    toggleMore(event) {
        event.preventDefault();

        this.setState({
            isMoreOpen: !this.state.isMoreOpen
        }, () => { this.showHideMoreTitles() });
    }

    showHideMoreTitles() {
        var btn = document.getElementById("btn-collapse-open");
        if (this.state.isMoreOpen) {
            btn.classList.add("hidden");
        }
        else {
            btn.classList.remove("hidden");
        }
    }

    linkToNewSearch() {
        console.log("linkToNewSearch() with ", this.state.vehicleSearchFilterValues);

        this.props.history.push({
            pathname: "/",
            state: {
                vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
            }
        });
    }

    componentDidMount() {
        if (this.state.vehicleSearchFilterValues.locations.every(location => !location.selected)) {
            this.populateLocationsData();
        } 
    }

    async populateLocationsData() {
        const response = await fetch('vehiclelocations', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            }),
        });
        const data = await response.json();

        this.setState(prevState => {
            let vehicleSearchFilterValues = Object.assign({}, prevState.vehicleSearchFilterValues)
            vehicleSearchFilterValues.locations = data
            return { vehicleSearchFilterValues }
        });
    }

    render() {
        let modelNames = ['Scala', 'Citigo', 'Fabia', 'Octavia', 'Kamiq', 'Karoq', 'Kodiaq', 'Superb', 'Rapid', 'Enyaq'];

        let selectedLocations = this.state.vehicleSearchFilterValues.locations.filter(location => {
            return location.selected === true;
        });
            
        return (
            <div>
                <Form className='search-form'>
                    <FormGroup className="search-form-form-group ml-3 mt-5 mr-4">
                        <Label className="search-form-label">
                            <h4>Malli</h4>
                        </Label>

                        <Dropdown name="modelName"
                            className='search-form-dropdown'
                            isOpen={this.state.dropdownOpenModelName}
                            toggle={this.toggleModelName}>
                            <DropdownToggle caret>
                                Valitse
                            </DropdownToggle>
                            <DropdownMenu>
                                {modelNames.map(modelNames => (
                                    <DropdownItem key={modelNames}
                                        onClick={() => this.toggleModelName}>{modelNames}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>

                        <CardGroup>
                            {this.state.vehicleSearchFilterValues.selectedModelNames.map(selectedModelName => (
                                <Card className="search-form-card mt-3" key={selectedModelName}>
                                    <CardBody className="search-form-card-body pl-2 pt-2 pr-2 pb-2 mr-2">
                                        <CardTitle className="search-form-card-title">
                                            <Button close className="search-form-close"
                                                onClick={() => this.handleSelectedModelClick(selectedModelName)} />
                                        </CardTitle>
                                        <CardText>{selectedModelName}</CardText>
                                    </CardBody>
                                </Card>
                            ))}
                        </CardGroup>
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4">
                        <Label for="bodyTypeCheckbox" className="search-form-custom-control-title">Korimalli</Label>
                        <div className="search-form-custom-div">
                            <CustomInput type="checkbox"
                                id="bodyTypeCustomCheckbox" name="isBodyTypeSedanSelected"
                                label="Sedan" value="1"
                                checked={this.state.vehicleSearchFilterValues.isBodyTypeSedanSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="bodyTypeCustomCheckbox2" name="isBodyTypeFarmariSelected"
                                label="Farmari" value="5"
                                checked={this.state.vehicleSearchFilterValues.isBodyTypeFarmariSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="bodyTypeCustomCheckbox3" name="isBodyTypeHacthbackSelected"
                                label="Hatchback" value="3"
                                checked={this.state.vehicleSearchFilterValues.isBodyTypeHacthbackSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="bodyTypeCustomCheckbox4" name="isBodyTypeSUVSelected"
                                label="SUV" value='{"first":"7","second":"8"}'
                                checked={this.state.vehicleSearchFilterValues.isBodyTypeSUVSelected}
                                onChange={this.handleChange} />
                        </div>
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4">
                        <Label for="fuelCheckbox" className="search-form-custom-control-title">K&auml;ytt&ouml;voima</Label>
                        <div className="search-form-custom-div">
                            <CustomInput type="checkbox"
                                id="fuelCustomCheckbox" name="isFuelBensiiniSelected"
                                label="Bensiini" value="1"
                                checked={this.state.vehicleSearchFilterValues.isFuelBensiiniSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="fuelCustomCheckbox2" name="isFuelDieselSelected"
                                label="Diesel" value="2"
                                checked={this.state.vehicleSearchFilterValues.isFuelDieselSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="fuelCustomCheckbox3" name="isFuelKaasuSelected"
                                label="Kaasu" value="3"
                                checked={this.state.vehicleSearchFilterValues.isFuelKaasuSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="fuelCustomCheckbox4" name="isFuelSahkoSelected"
                                label="S&auml;hk&ouml;" value="4"
                                checked={this.state.vehicleSearchFilterValues.isFuelSahkoSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="fuelCustomCheckbox5" name="isFuelHybridiSelected"
                                label="Hybridi" value="5"
                                className="search-form-form-check-input"
                                checked={this.state.vehicleSearchFilterValues.isFuelHybridiSelected}
                                onChange={this.handleChange} />
                        </div>
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4">
                        <Label for="transmissionCheckbox" className="search-form-custom-control-title">Vaihteisto</Label>
                        <div className="search-form-custom-div">
                            <CustomInput type="checkbox"
                                id="transmissionCustomCheckbox" name="isTransmissionManualSelected"
                                label="Manuaali" value="1"
                                checked={this.state.vehicleSearchFilterValues.isTransmissionManualSelected}
                                onChange={this.handleChange} />
                            <CustomInput type="checkbox"
                                id="transmissionCustomCheckbox2" name="isTransmissionAutomaticSelected"
                                label="Automaatti" value="2"
                                checked={this.state.vehicleSearchFilterValues.isTransmissionAutomaticSelected}
                                onChange={this.handleChange} />
                        </div>
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4">
                        <Label for="fourWheelDriveCheckbox" className="search-form-custom-control-title">Vetotapa</Label>
                        <div>
                            <CustomInput type="checkbox"
                                id="fourWheelDriveCheckbox" name="isFourWheelDriveSelected"
                                label="Neliveto" value="1"
                                checked={this.state.vehicleSearchFilterValues.isFourWheelDriveSelected}
                                onChange={this.handleChange} />
                        </div>
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4 pb-4">
                        <FormGroup>
                            <h4>Hinta</h4>
                            <InputRange
                                maxValue={80000}
                                minValue={0}
                                step={1000}
                                ref="input_range"
                                value={this.state.vehicleSearchFilterValues.askPrice}
                                allowSameValues={true}
                                onChange={askPrice => {
                                    var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues };

                                    if (askPrice.min < 0) {
                                        askPrice.min = 0;
                                    }
                                    if (askPrice.max > 80000) {
                                        askPrice.max = 80000;
                                    }

                                    vehicleSearchFilterValues.askPrice = askPrice;
                                    this.setState({ vehicleSearchFilterValues });
                                }}
                                onChangeComplete={askPrice => {
                                    this.linkToNewSearch();
                                }}
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4 pb-4">
                        <FormGroup>
                            <h4>Mittarilukema</h4>
                            <InputRange
                                maxValue={130000}
                                minValue={0}
                                step={1000}
                                ref="input_range"
                                value={this.state.vehicleSearchFilterValues.mileage}
                                allowSameValues={true}
                                onChange={mileage => {
                                    var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues };

                                    if (mileage.min < 0) {
                                        mileage.min = 0;
                                    }
                                    if (mileage.max > 130000) {
                                        mileage.max = 130000;
                                    }

                                    vehicleSearchFilterValues.mileage = mileage;
                                    this.setState({ vehicleSearchFilterValues });
                                }}
                                onChangeComplete={askPrice => {
                                    this.linkToNewSearch();
                                }}
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4 pb-4">
                        <h4>K&auml;ytt&ouml;&ouml;nottovuosi</h4>
                        <InputRange
                            maxValue={new Date().getFullYear()}
                            minValue={(new Date().getFullYear()) - 5}
                            step={1}
                            ref="input_range"
                            value={this.state.vehicleSearchFilterValues.year}
                            allowSameValues={true}
                            onChange={year => {
                                var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues };
                                vehicleSearchFilterValues.year = year;
                                this.setState({ vehicleSearchFilterValues });
                            }}
                            onChangeComplete={mileage => {
                                this.linkToNewSearch();
                            }}
                        />
                    </FormGroup>

                    <FormGroup className="search-form-form-group ml-3 mt-4 mr-4 tp-4">
                        <Label className="search-form-label">
                            <h4>Sijainti</h4>
                        </Label>

                        <Dropdown name="location"
                            className='search-form-dropdown'
                            isOpen={this.state.dropdownOpenLocation}
                            toggle={this.toggleLocation}>
                            <DropdownToggle caret>
                                Valitse
                            </DropdownToggle>
                            <DropdownMenu>
                                {this.state.vehicleSearchFilterValues.locations.map((location, index) => {
                                    return !location.selected && (<DropdownItem key={location.location} value={index} onClick={() => this.toggleLocation}>{location.location}</DropdownItem>)
                                })}
                            </DropdownMenu>
                        </Dropdown>

                        <CardGroup>
                            {selectedLocations.map(selectedLocation => (
                                <Card className="search-form-card mt-3" key={selectedLocation.location}>
                                    <CardBody className="search-form-card-body pl-2 pt-2 pr-2 pb-2 mr-2">
                                        <CardTitle className="search-form-card-title">
                                            <Button close className="search-form-close"
                                                onClick={() => this.handleSelectedLocationClick(selectedLocation.location)} />
                                        </CardTitle>
                                        <CardText>{selectedLocation.location}</CardText>
                                    </CardBody>
                                </Card>
                            ))}
                        </CardGroup>
                    </FormGroup>

                    <div className="search-form-more-filters">
                        <div className="centered">
                            <button id="btn-collapse-open" className="mr-4 btn btn-ghost" onClick={this.toggleMore} role="button">
                                Lis&auml;&auml; hakukriteerej&auml;
                            </button>
                        </div>
                        <Collapse isOpen={this.state.isMoreOpen}>
                            <FormGroup className="search-form-form-group ml-3 mt-4 mr-4">
                                <div>
                                    <CustomInput type="checkbox"
                                        id="towHitchCheckbox" name="isTowHitchSelected"
                                        label="Vetokoukku"
                                        value="vetokoukku"
                                        checked={this.state.vehicleSearchFilterValues.isTowHitchSelected}
                                        onChange={this.handleChange} />
                                </div>
                                <div>
                                    <CustomInput type="checkbox"
                                        id="fuelDrivenAuxiliaryHeaterCheckbox" name="isFuelDrivenAuxiliaryHeaterSelected"
                                        label="Polttoainekäyttöinen lis&auml;l&auml;mmitin"
                                        value="lis&auml;l&auml;mmitin"
                                        checked={this.state.vehicleSearchFilterValues.isFuelDrivenAuxiliaryHeaterSelected}
                                        onChange={this.handleChange} />
                                </div>
                                <div>
                                    <CustomInput type="checkbox"
                                        id="cruiseControlCheckbox" name="isCruiseControlSelected"
                                        label="Vakionopeuden s&auml;&auml;din"
                                        value="vakionopeuden s&auml;&auml;din"
                                        checked={this.state.vehicleSearchFilterValues.isCruiseControlSelected}
                                        onChange={this.handleChange} />
                                </div>
                                <div>
                                    <CustomInput type="checkbox"
                                        id="metallicCheckbox" name="isMetallicSelected"
                                        label="Metalliv&auml;ri"
                                        value="metalliv&auml;ri"
                                        checked={this.state.vehicleSearchFilterValues.isMetallicSelected}
                                        onChange={this.handleChange} />
                                </div>
                            </FormGroup>

                            <FormGroup className="search-form-form-group ml-3 mt-4 mr-4 tp-4">
                                <h4>P&auml;&auml;st&ouml;t (CO<sub>2</sub>) g/km</h4>
                                <InputRange
                                    maxValue={220}
                                    minValue={-1}
                                    step={10}
                                    ref="input_range"
                                    value={this.state.vehicleSearchFilterValues.co2}
                                    allowSameValues={true}
                                    onChange={co2 => {
                                        var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues };
                                        vehicleSearchFilterValues.co2 = co2;
                                        this.setState({ vehicleSearchFilterValues });
                                    }}
                                    onChangeComplete={co2 => {
                                        this.linkToNewSearch();
                                    }}
                                />
                            </FormGroup>

                            <div className="centered">
                                <button id="btn-collapse-close" className="mr-4 btn btn-ghost" onClick={this.toggleMore} role="button">
                                    V&auml;hemm&auml;n hakukriteerej&auml;
                                </button>
                            </div>
                        </Collapse>
                    </div>
                </Form>
            </div>
        );
    }
}