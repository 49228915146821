import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../custom.css';
import './Footer.css';
import arrowUp from '../images/arrowUp.png';

export class Footer extends Component {
    static displayName = Footer.name;

    scrollTop() {
        window.scrollTo(0, 600);
    }

    render() {
        return (
            <div className="footer">
                <Row>
                    <Col md='2'></Col>
                    <Col md='6'><span>&copy;2021 &Scaron;koda Auto a.s.</span></Col>
                    <Col md='1'><a href='https://www.skoda.fi/muut/cookie-settings' target='_blank' rel='noreferrer'>EVÄSTEET</a></Col>
                    <Col md='2'>
                        <img src={arrowUp} alt="Ylös" onClick={() => this.scrollTop()} className="arrowUp" />
                    </Col>
                </Row>
            </div>
        );
    }
}
