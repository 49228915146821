import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { FooterLinks } from './FooterLinks';
import { Footer } from './Footer';
import CookieConsent from './CookieConsent';
import Symbols from './Symbols';

export class Layout extends Component {
    static displayName = Layout.name;

    render () {
        return (
            <div>
                <NavMenu />
                {/*<Container>*/}
                    {this.props.children}
                {/*</Container>*/}
                <FooterLinks />
                <Footer />
                <CookieConsent />
                <Symbols />
            </div>
        );
    }
}
