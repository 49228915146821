import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { CustomInput } from 'reactstrap';
import GoogleTagManager from './GoogleTagManager';
import { PrimaryBannerButton } from './PrimaryBannerButton';

import '../custom.css';
import './ContactForm.css';

import arrowBackBig from '../images/arrow-back-big.svg';
import arrowBackSmall from '../images/arrow-back-small.svg';

export class ContactForm extends Component {
    static displayName = ContactForm.name;

    constructor(props) {
        super(props);

        let vehicleResult = props.location.state !== undefined && props.location.state.vehicleResult !== undefined ?
            JSON.parse(props.location.state.vehicleResult) : null;

        var messagePlaceholderValue = "Hei, olen kiinnostunut Loisto-autosta ";

        if (vehicleResult !== null) {
            messagePlaceholderValue = messagePlaceholderValue + vehicleResult.makeName + ' '
                + vehicleResult.modelName + ' rekisterinumero ' + vehicleResult.formattedLicensePlateNumber;
        }

        this.state = {
            vehicleResult: vehicleResult,
            name: '',
            email: '',
            email2: '',
            phone: '',
            interestedInTestDriveSelected: false,
            interestedInBuyingSelected: false,
            interestedInChangingSelected: false,
            message: messagePlaceholderValue,
            isContactTypeEmailSelected: false,
            isContactTypePhoneSelected: false,
            isSendCopySelected: false,
            isEmailsTheSame: true,
            isSentWithErrors: false,
            isSentWithoutErrors: false,
            loading: false,
            interestedInChangingReg: '',
            interestedInChangingKm: '',
            gdbrAcceptedSelected: false,
            marketingByEmailAllowedSelected: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "interestedInChangingSelected")
            if (!target.checked)
                this.setState({
                    interestedInChangingReg: '',
                    interestedInChangingKm: ''
                });
 
        this.setState({
            [name]: value,
            isEmailsTheSame: true,
            }
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);

        if (this.state.email !== this.state.email2) {
            this.setState({
                    isEmailsTheSame: false
                }
            );
        } else {
            this.setState({
                    loading: true
                }
            );

            fetch('InfoDealer',
                {
                    method: "POST",
                    body: JSON.stringify(this.state),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                }).then(
                (response) => (response.json())
            ).then((response) => {
                if (response === true) {
                    this.setState({
                        isSentWithoutErrors: true,
                        loading: false
                    });
                } else {
                    this.setState({
                        isSentWithErrors: true,
                        loading: false
                    });
                }
            });
        }
    }

    render() {
        let bannerClassName = "vehicle-page-banner";
        if (window.supportsWebp === false) {
            bannerClassName = "vehicle-page-banner-no-webp";
        }
        const interestedInChangingContent = this.state.interestedInChangingSelected
            ? <div className="interestedInChangingContent">
                <div className="skoda-text-input">
                    <span className="wrapper">
                        <input
                            id="interestedInChangingReg"
                            name="interestedInChangingReg"
                            type="text"
                            className="form-control changingReg"
                            aria-describedby="interestedInChangingReg"
                            value={this.state.interestedInChangingReg}
                            onChange={this.handleChange}
                            maxLength="8"
                            placeholder=" "
                        />
                        <label htmlFor="interestedInChangingReg">Vaihdossa annettavan auton rekisterinumero</label>
                    </span>
                    <small>&nbsp;</small>
                </div>
                <div className="skoda-text-input">
                    <span className="wrapper">
                        <input
                            id="interestedInChangingKm"
                            name="interestedInChangingKm"
                            type="number"
                            className="form-control"
                            aria-describedby="interestedInChangingKm"
                            value={this.state.interestedInChangingKm}
                            onChange={this.handleChange}
                            maxLength="6"
                            placeholder=" "
                            min="0"
                            max="999999"
                        />
                        <label htmlFor="interestedInChangingKm">Vaihdossa annettavan auton kilometrilukema</label>
                    </span>
                    <small>&nbsp;</small>
                </div>
              </div>
            : null;
        const event = { platform: 'react-stack' }
        return (
            <Container>
                {/*<GoogleTagManager gtmId='GTM-5KVJGP3' scriptId='gtm-script-container' dataLayerName='contactForm' additionalEvents={event} previewVariables='' />*/}
                <div className={bannerClassName}>
                    <Row>
                        <Col xs='0' sm='0' md='0' lg='10' xl='10'>
                            <div className="vehicle-page-bannerTextArea">
                                <p className="bannerTitle">Loisto</p>
                                <p className="bannerText">Vaihtoautojen valioyksilöt</p>
                                <Route render={({ history }) => (
                                    <PrimaryBannerButton
                                        history={history} contentPagePath="/content" contentPageTitle="Lue lisää" />
                                )} />
                            </div>
                        </Col>
                    </Row>
                </div>

                
                <Row>
                    <Col xs='12' sm='12' md='12' lg='3' xl='2'>

                        <div className="d-none d-lg-block">

                            <div className="left-column-back ml-5 mt-5 mr-2 mb-2">
                                <Link to={{
                                    pathname: "/"
                                }}>
                                    <img src={arrowBackBig} alt="Back" className="mr-4 pt-2"></img>
                                    <span>Loisto<br />-vaihtoautot</span>
                                </Link>
                            </div>
                        </div>
                    </Col>

                    {!this.state.isSentWithErrors && !this.state.isSentWithoutErrors && !this.state.loading &&
                        (<Col xs='0' sm='0' md='0' lg='9' xl='10'>
                        <div className="content-area">

                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                    <div className="content-area-back pt-4">
                                        <Link to={{
                                            pathname: "/",
                                            state: {
                                                vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                            }
                                        }}>
                                            <img src={arrowBackSmall} alt="Back" className="mr-2 mb-1"></img>
                                            Loisto-vaihtoautot
                                        </Link>
                                    </div>

                                    <h1>Ota yhteytt&auml;</h1>

                                    <div className="App">
                                        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                                            <div className="skoda-text-input">
                                                <span className="wrapper">
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        placeholder=" "
                                                        required
                                                        value={this.state.name}
                                                        onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="name">Nimi</label>
                                                </span>
                                                <small>&nbsp;</small>
                                            </div>

                                            <div className="skoda-text-input">
                                                <span className="wrapper">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        placeholder=" "
                                                        aria-describedby="emailHelp"
                                                        required
                                                        value={this.state.email}
                                                        onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="email">S&auml;hk&ouml;postiosoite</label>
                                                </span>
                                                <small>&nbsp;</small>
                                            </div>

                                            <div className="skoda-text-input">
                                                <span className="wrapper">
                                                    <input
                                                        id="email2"
                                                        name="email2"
                                                        type="email"
                                                        placeholder=" "
                                                        aria-describedby="emailHelp"
                                                        required
                                                        value={this.state.email2}
                                                        onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="email2">S&auml;hk&ouml;postiosoite uudelleen</label>
                                                 </span>
                                                <small>&nbsp;</small>
                                            </div>

                                            <div className="skoda-text-input">
                                                <span className="wrapper">
                                                    <input
                                                        id="phone"
                                                        name="phone"
                                                        type="text"
                                                        placeholder=" "
                                                        aria-describedby="phoneHelp"
                                                        required
                                                        value={this.state.phone}
                                                        onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="phone">Puhelinnumero</label>
                                                </span>
                                                <small>&nbsp;</small>
                                            </div>

                                            <fieldset>
                                                <legend>Olen kiinnostunut</legend>
                                                <ul className="default-list form-group">
                                                    <li>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="interestedInCustomCheckbox"
                                                            name="interestedInTestDriveSelected"
                                                            label="Koeajosta"
                                                            value="testDrive"
                                                            checked={this.state.interestedInTestDriveSelected}
                                                            onChange={this.handleChange}
                                                        />
                                                    </li>
                                                    <li>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="interestedInCustomCheckbox2"
                                                            name="interestedInBuyingSelected"
                                                            label="Ostosta"
                                                            value="buying"
                                                            checked={this.state.interestedInBuyingSelected}
                                                            onChange={this.handleChange}
                                                        />
                                                    </li>
                                                    <li>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="interestedInCustomCheckbox3"
                                                            name="interestedInChangingSelected"
                                                            label="Vaihdosta"
                                                            value="changing"
                                                            checked={this.state.interestedInChangingSelected}
                                                            onChange={this.handleChange}
                                                        />
                                                        {interestedInChangingContent}
                                                    </li>
                                                </ul>
                                            </fieldset>

                                            <div className="skoda-textarea">
                                                <span className="wrapper">
                                                    <textarea
                                                        id="message"
                                                        name="message"
                                                        className="form-control"
                                                        rows="5"
                                                        placeholder=" "
                                                        value={this.state.message}
                                                        onChange={this.handleChange}
                                                    />
                                                    <label htmlFor="message">Viesti</label>
                                                </span>
                                            </div>

                                            <fieldset>
                                                <legend>Haluamani vastaustapa</legend>
                                                <ul className="default-list form-group">
                                                    <li>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="contactTypeCustomCheckbox"
                                                            name="isContactTypeEmailSelected"
                                                            label="Sähköpostitse"
                                                            value="email"
                                                            checked={this.state.isContactTypeEmailSelected}
                                                            onChange={this.handleChange}
                                                        />
                                                    </li>
                                                    <li>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="contactTypeCustomCheckbox2"
                                                            name="isContactTypePhoneSelected"
                                                            label="Puhelimitse"
                                                            value="phone"
                                                            checked={this.state.isContactTypePhoneSelected}
                                                            onChange={this.handleChange}
                                                        />
                                                    </li>
                                                </ul>
                                            </fieldset>

                                            <div className="form-group checkboxes_info">
                                                <CustomInput
                                                    className="mb-3"
                                                    type="checkbox"
                                                    id="gdbrAccepted"
                                                    name="gdbrAcceptedSelected"
                                                    label={
                                                        <div>
                                                            <span>
                                                                Olen lukenut ja ymmärtänyt Škoda:n&nbsp;
                                                            </span>
                                                            <a
                                                                href="https://www.skoda.fi/skoda/tietosuoja"
                                                                target="_blank"
                                                                rel='noreferrer'
                                                                id="gdbrLink">
                                                                tietoturvaselosteen
                                                            </a>
                                                        </div>
                                                    }
                                                    checked={this.state.gdbrAcceptedSelected}
                                                    onChange={this.handleChange}
                                                />
                                                <CustomInput
                                                    className="marketingByEmailAllowed mb-3"
                                                    type="checkbox"
                                                    id="marketingByEmailAllowed"
                                                    name="marketingByEmailAllowedSelected"
                                                    label="Haluan vastaanottaa ajankohtaista tietoa Škoda –tuotteista ja palveluista sähköpostilla"
                                                    checked={this.state.marketingByEmailAllowedSelected}
                                                    onChange={this.handleChange}
                                                />
                                                <CustomInput
                                                    className="form-group send-copy-box mb-3"
                                                    type="checkbox"
                                                    id="sendCopyCustomCheckbox"
                                                    name="isSendCopySelected"
                                                    label="Lähetä viestistä kopio sähköpostiini"
                                                    value="emailCopy"
                                                    checked={this.state.isSendCopySelected}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
 
                                            {!this.state.isEmailsTheSame &&
                                                <div>
                                                    <label className="required">Syötetyt sähköpostiosoitteet eivät täsmää. Tarkista antamasi tiedot.</label>
                                                    <span className="required"> ! </span>
                                                </div>
                                            }

                                            <div className="mt-3 mb-3">
                                                <button
                                                    type="submit"
                                                    className="skoda-btn btn-primary"
                                                    disabled={!this.state.gdbrAcceptedSelected}
                                                >
                                                Lähetä yhteydenottolomake
                                                </button>
                                            </div>
                                            <small>Tämän viestin vastaanottaja: {this.state.vehicleResult.sellerCompanyName}</small>
                                        </form>
                                    </div>

                                </Col>
                            </Row>

                        </div>
                        </Col>)}
                    {!this.state.isSentWithErrors && this.state.isSentWithoutErrors && !this.state.loading &&
                        (<Col xs='0' sm='0' md='0' lg='9' xl='10'>
                            <div className="content-area">
                                <Row>
                                    <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                        <div className="content-area-back pt-4">
                                            <Link to={{
                                                pathname: "/",
                                                state: {
                                                    vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                                }
                                            }}>
                                                <img src={arrowBackSmall} alt="Back" className="mr-2 mb-1"></img>
                                            Loisto-vaihtoautot
                                        </Link>
                                        </div>

                                        <h1>Ota yhteytt&auml;</h1>

                                        <div className="App">
                                                <div className="text-content ">
                                                    Viesti on lähetetty.
                                                </div>
                                        </div>

                                    </Col>
                                </Row>

                            </div>
                        </Col>)}
                        {this.state.isSentWithErrors && !this.state.isSentWithoutErrors && !this.state.loading &&
                            (<Col xs='0' sm='0' md='0' lg='9' xl='10'>
                                <div className="content-area">
                                    <Row>
                                        <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                            <div className="content-area-back pt-4">
                                                <Link to={{
                                                    pathname: "/",
                                                    state: {
                                                        vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                                                    }
                                                }}>
                                                    <img src={arrowBackSmall} alt="Back" className="mr-2 mb-1"></img>
                                                Loisto-vaihtoautot
                                            </Link>
                                            </div>

                                            <h1>Ota yhteytt&auml;</h1>

                                            <div className="App">
                                                <div className="text-content ">
                                            <label className="required">Lomakkeen lähetys epäonnistui, kokeile myöhemmin uudestaan tai ole suoraan yhteydessä jälleenmyyjään.</label>
                                                    </div>
                                            </div>

                                        </Col>
                                    </Row>

                                </div>
                            </Col>)}
                            {this.state.loading &&
                                (<Col xs='0' sm='0' md='0' lg='9' xl='10'>
                                    <div className="content-area">
                                        <Row>
                                            <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                                                <div className="loading" key="loading">
                                                    <Spinner animation="border" role="status">
                                                    </Spinner>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </Col>)}
                </Row>

            </Container>
        )
    }
}