/*Due to existing implementation, I found no other easy way to reset filters on the welcome page. Another option is to refactor code to pass parameters correctly. In this case it means to rewrite code completely.*/

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'reactstrap';

import { VehicleSearchFilters } from './VehicleSearchFilters';

export class ReloadFiltersBlock extends Component {

    constructor(props) {
        super(props);

        let vehicleSearchFilters = JSON.parse(sessionStorage.getItem('session_vehicleSearchFilterValues'));
        let vehicleSearchResult = JSON.parse(sessionStorage.getItem('session_vehicleSearchResult'));

        this.state = {
            vehicleSearchResult: vehicleSearchResult,
            vehicleSearchFilterValues: vehicleSearchFilters
        };
    }
    componentDidMount() {
            var vehicleSearchFilterValues = { ...this.state.vehicleSearchFilterValues }

            vehicleSearchFilterValues.selectedModelNames = [];
            vehicleSearchFilterValues.isBodyTypeSedanSelected = false;
            vehicleSearchFilterValues.isBodyTypeFarmariSelected = false;
            vehicleSearchFilterValues.isBodyTypeHacthbackSelected = false;
            vehicleSearchFilterValues.isBodyTypeSUVSelected = false;
            vehicleSearchFilterValues.selectedBodyTypeIds = [];
            vehicleSearchFilterValues.isFuelBensiiniSelected = false;
            vehicleSearchFilterValues.isFuelDieselSelected = false;
            vehicleSearchFilterValues.isFuelKaasuSelected = false;
            vehicleSearchFilterValues.isFuelSahkoSelected = false;
            vehicleSearchFilterValues.isFuelHybridiSelected = false;
            vehicleSearchFilterValues.selectedFuelIds = [];
            vehicleSearchFilterValues.isTransmissionManualSelected = false;
            vehicleSearchFilterValues.isTransmissionAutomaticSelected = false;
            vehicleSearchFilterValues.selectedTransmissionIds = [];
            vehicleSearchFilterValues.isFourWheelDriveSelected = false;

            if (vehicleSearchFilterValues.askPrice !== undefined) {
                vehicleSearchFilterValues.askPrice.min = 0;
                vehicleSearchFilterValues.askPrice.max = 80000;
            } else {
                vehicleSearchFilterValues.askPrice = {
                    min: 0,
                    max: 80000
                }
            }

            if (vehicleSearchFilterValues.mileage !== undefined) {
                vehicleSearchFilterValues.mileage.min = 0;
                vehicleSearchFilterValues.mileage.max = 130000;
            } else {
                vehicleSearchFilterValues.mileage = {
                    min: 0,
                    max: 130000
                }
            }

            if (vehicleSearchFilterValues.locations !== undefined) {
                for (var index = 0; index < vehicleSearchFilterValues.locations.length; ++index) {
                    vehicleSearchFilterValues.locations[index].selected = false;
                }
            } else {
                vehicleSearchFilterValues.locations = [];
            }

            if (vehicleSearchFilterValues.year !== undefined) {
                vehicleSearchFilterValues.year.min = (new Date().getFullYear()) - 5;
                vehicleSearchFilterValues.year.max = new Date().getFullYear();
            } else {
                vehicleSearchFilterValues.year = {
                    min: (new Date().getFullYear()) - 5,
                    max: new Date().getFullYear()
                }
            }
            vehicleSearchFilterValues.selectedModelNames = [];
            vehicleSearchFilterValues.selectedLocationIds = [];
            vehicleSearchFilterValues.isTowHitchSelected = false;
            vehicleSearchFilterValues.isFuelDrivenAuxiliaryHeaterSelected = false;
            vehicleSearchFilterValues.isCruiseControlSelected = false;
            vehicleSearchFilterValues.isMetallicSelected = false;
            vehicleSearchFilterValues.accessories = [];

            if (vehicleSearchFilterValues.co2 !== undefined) {
                vehicleSearchFilterValues.co2.min = -1;
                vehicleSearchFilterValues.co2.max = 220;
            } else {
                vehicleSearchFilterValues.co2 = {
                    min: -1,
                    max: 220
                }
            }


            this.setState({
                vehicleSearchFilterValues
            }, () => {
                this.props.history.push({
                    pathname: "/",
                    state: {
                        vehicleSearchFilterValues: JSON.stringify(this.state.vehicleSearchFilterValues)
                    }
                });
            });
    }
    render() {
        return (
            <Container>
                <Route render={({ history }) => (
                    <VehicleSearchFilters
                        history={history}
                        currentVehicleSearchFilterValues={JSON.stringify(this.state.vehicleSearchFilterValues)} />
                )} />
            </Container>
        )
    }
}