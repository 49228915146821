import React from 'react'
import Modal from 'react-modal'
import Cookies from 'js-cookie'
import i18n from 'i18next'
import { withTranslation, initReactI18next } from 'react-i18next'
import RenderGate from './RenderGate'

import './CookieConsent.css'

// Usage of the component:
//
// Add an event listener before scripts that set cookies as below:
//
// window.addEventListener('consentClosed', function (event) {
//      if (window.cookieComponent.getConsent('analytics')) {
//          <script>Analytics cookie setting script</script>
//      }
// }

// For one-liner scripts that set cookies:
//
// Add src of the script initially as a data-attribute "data-consent-src", e.g. data-consent-src="scriptSrc" and leave src attribute empty or do not set it at all
// Add the cookie category of the script as a data-attribute, e.g. data-cookie-analytics, data-cookie-functional or data-cookie-marketing

// It's better to move the translations into a separate JSON file and not initialize i18n in this component
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            fi: {
                translation: {
                    mainHeader: 'Yksityisyytesi on meille tärkeää',
                    cookieInfo: 'Evästeitä käytetään asetustesi ja valintojesi tallentamiseen, liikenteemme analysointiin,'
                        + ' sosiaalisen median ominaisuuksien tuottamiseen sekä sisällön ja mainonnan kohdentamiseen.'
                        + ' Luovutamme myös tietoja sivustomme käytöstä sosiaalisen median sekä mainonta- ja analytiikkakumppaneillemme.'
                        + ' Annat suostumuksesi yritykselle ŠKODA AUTO a.s., Helkama- Auto Oy, ja ŠKODAn jakeluverkostolle.'
                        + ' Lue lisää kohdasta',
                    cookiePolicy: 'Evästekäytäntö',
                    manageCookies: 'Evästeiden hallinta',
                    accept: 'Hyväksy kaikki',
                    allow: 'Salli kaikki',
                    privacyControlCenter: 'Tietosuojavalintakeskus',
                    privacyInfo: 'Aina kun käyt jollakin verkkosivustolla, se voi tallentaa tai hakea tietoja selaimestasi, enimmäkseen evästeiden muodossa.'
                        + ' Nämä tiedot voivat liittyä sinuun, valintoihisi tai laitteeseesi, ja niitä käytetään lähinnä huolehtimaan siitä,'
                        + ' että sivusto toimii odotetulla tavalla.'
                        + ' Tietojen perusteella ei tavallisesti voida suoraan tunnistaa sinua,'
                        + ' mutta ne voivat antaa sinulle henkilökohtaisemman verkkokokemuksen.'
                        + ' Koska tietosuojasi on meille tärkeä asia, voit kieltäytyä sallimasta tietyntyyppisiä evästeitä.'
                        + ' Napsauttamalla luokkien otsikoita saat lisätietoja ja voit muuttaa oletusasetuksiamme.'
                        + ' Tietyntyyppisten evästeiden estäminen voi kuitenkin vaikuttaa verkkosivustokokemukseesi tarjoamiimme palveluihin.'
                        + ' Aktivoimalla minkä tahansa alla olevista evästeistä annat myös nimenomaisen suostumuksen siihen,'
                        + ' että henkilötietosi voidaan siirtää kolmansiin maihin.'
                        + ' Lisätietoja siirrosta ja siihen liittyvistä riskeistä löytyy evästekäytännössä.',
                    additionalInfo: 'Lisätiedot',
                    controlManagement: 'Evästevalintojen hallinta',
                    alwaysActive: 'Aina aktiivinen',
                    necessary: 'Tarvittavat evästeet',
                    analytics: 'Kehittynyt analytiikka',
                    functional: 'Toiminnot ja valinnat',
                    marketing: 'Kohdennetut tarjoukset',
                    necessaryInfo: 'Tarvittavilla evästeillä otetaan käyttöön verkkosivuston ydintoiminnot kuten tietoturva,'
                        + ' verkonhallinta, käytettävyys ja perustilastotiedot sivustolla kävijöistä.'
                        + ' Voit ottaa nämä evästeet pois käytöstä muuttamalla selaimesi asetuksia, mutta tämä voi vaikuttaa sivuston toimintaan.',
                    analyticsInfo: 'Kolmannen osapuolen työkalut, kuten Google-analytiikka,'
                        + ' tarjoavat meille mahdollisuuden verkkosivuston parantamiseen raportoimalla verkkosivuston käytöstäsi.'
                        + ' Evästeillä kerättyjen tietojen perusteella ei voida tunnistaa ketään.',
                    functionalInfo: 'Toiminto- ja valintaevästeillä otetaan käyttöön kehittynyt verkkosivustosisältö ja kehittyneet ominaisuudet,'
                        + ' ja niiden avulla tallennetaan asetuksesi ja valintasi.',
                    marketingInfo: 'Käytämme näitä evästeitä SKODA AUTOon sekä kolmansien osapuolten verkkosivustoihin'
                        + ' ja sosiaaliseen mediaan osoittamasi mielenkiinnon kohteisiin perustuvien kohdennettujen tarjousten,'
                        + ' sisältöjen ja mainosten näyttämiseen.',
                    confirm: 'Vahvista valinnat'
                }
            }
        },
        lng: 'fi', // if you're using a language detector, do not define the lng option
        fallbackLng: 'fi',

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '0',
        overflow: 'hidden',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: '650px',
        maxHeight: '65%',
        display: 'flex',
        fontSize: '18px'
    }
}

class CookieConsent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
            settingsOpen: false,
            analytics: false,
            functional: false,
            marketing: false
        }

        this.closeModal = this.closeModal.bind(this)
        this.acceptAllCookies = this.acceptAllCookies.bind(this)
        this.openSettings = this.openSettings.bind(this)
        this.handleAnalytics = this.handleAnalytics.bind(this)
        this.handleFunctional = this.handleFunctional.bind(this)
        this.handleMarketing = this.handleMarketing.bind(this)
        this.getConsent = this.getConsent.bind(this)
        this.setCookiesOnConsent = this.setCookiesOnConsent.bind(this)
        this.setScriptSrc = this.setScriptSrc.bind(this)

        window.cookieComponent = this
    }

    componentDidMount() {
        const consentCookie = Cookies.get('ConsentInformationLoisto')

        if (!consentCookie || consentCookie === undefined) {
            this.setState({
                modalOpen: true
            })
        } else {
            const categories = consentCookie.split(',')

            this.setState({
                analytics: categories[0].includes('true'),
                functional: categories[1].includes('true'),
                marketing: categories[2].includes('true')
            })

            this.createConsentEvent()
        }
    }

    componentDidUpdate() {
        if (this.state.modalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflowY = 'unset';
            document.body.style.overflowX = 'hidden';
            this.setCookiesOnConsent()
        }
    }

    handleAnalytics(event) {
        this.setState({
            analytics: event.target.checked
        })
    }

    handleFunctional(event) {
        this.setState({
            functional: event.target.checked
        })
    }

    handleMarketing(event) {
        this.setState({
            marketing: event.target.checked
        })
    }

    setCookiesOnConsent() {
        const analyticsScripts = document.querySelectorAll('[data-cookie-analytics]')
        const functionalScripts = document.querySelectorAll('[data-cookie-functional]')
        const marketingScripts = document.querySelectorAll('[data-cookie-marketing]')

        this.setScriptSrc(this.state.analytics, analyticsScripts)
        this.setScriptSrc(this.state.functional, functionalScripts)
        this.setScriptSrc(this.state.marketing, marketingScripts)
    }

    setScriptSrc(isConsented, scripts) {
        if (isConsented && scripts) {
            scripts.forEach((script) => {
                if (script.dataset.consentSrc) {
                    script.src = script.dataset.consentSrc
                }
            })
        }
    }

    getConsent(category) {
        const consentGiven = (category === 'analytics' && this.state.analytics)
            || (category === 'functional' && this.state.functional)
            || (category === 'marketing' && this.state.marketing)

        if (consentGiven) return true

        return false
    }

    closeModal() {
        const { analytics, functional, marketing } = this.state

        Cookies.set('ConsentInformationLoisto', `Analytics:${analytics},Functional:${functional},Marketing:${marketing}`, { expires: 60 })

        this.setState({
            modalOpen: false
        })

        this.createConsentEvent()
    }

    openSettings() {
        this.setState({
            modalOpen: true,
            settingsOpen: true
        })
    }

    acceptAllCookies() {
        Cookies.set('ConsentInformationLoisto', 'Analytics:true,Functional:true,Marketing:true', { expires: 60 })

        this.setState({
            analytics: true,
            functional: true,
            marketing: true,
            modalOpen: false
        })

        this.createConsentEvent()
    }

    // In order to listen to user's consent changes to allow/block scripts in DOM
    createConsentEvent() {
        // Timeout to wait for component state to update before creating and listening to the event
        setTimeout(() => {
            const consentClosed = new CustomEvent('consentClosed')
            window.dispatchEvent(consentClosed)
        }, 0)
    }

    render() {
        const { t } = this.props

        if (!this.state.modalOpen) {
            return (
                <button className='settings-button' onClick={this.openSettings}>Evästeiden hallinta</button>
            )
        }

        return (
            <Modal
                isOpen={this.state.modalOpen}
                onRequestClose={() => this.closeModal()}
                ariaHideApp={false}
                style={modalStyles}
                contentLabel='Evästeiden hallinta'
                shouldCloseOnOverlayClick={false}
                bodyOpenClassName='cookie-consent-modal'
            >
                <div className="consent-dialog" role="dialog" aria-modal="true" aria-hidden="true">
                    <div className='consent-close'>
                        <button type="button" className="skoda-btn btn-icon btn-close" onClick={this.closeModal}>
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#close-x-black" />
                            </svg>
                            <span className="sr-only">Sulje</span>
                        </button>
                    </div>
                    <div className="dialog-inner">
                        <RenderGate shouldRender={!this.state.settingsOpen}>
                            <div className='cookie-info'>
                                <h2>{t('mainHeader')}</h2>
                                <p>
                                    {t('cookieInfo')}
                                    <a href='https://www.skoda.fi/_doc/ab85bf6a-761b-48b3-b397-48eaa25657b4' target='_blank' rel='noreferrer'> {t('cookiePolicy')}</a>.
                                </p>
                                <div className='cookie-buttons'>
                                    <button className='cookie-settings-button' onClick={this.openSettings}>{t('manageCookies')}</button>
                                    <button className='skoda-btn btn-primary' onClick={this.acceptAllCookies}>{t('accept')}</button>
                                </div>
                            </div>
                        </RenderGate>
                        <RenderGate shouldRender={this.state.settingsOpen}>
                            <div className='privacy-control'>
                                <h2>{t('privacyControlCenter')}</h2>
                                <p>{t('privacyInfo')}</p>
                                <a href='https://www.skoda.fi/_doc/ab85bf6a-761b-48b3-b397-48eaa25657b4' target='_blank' rel='noreferrer'>{t('additionalInfo')}</a>
                                <div className='accept-all-cookies'>
                                    <button className='skoda-btn btn-primary' onClick={this.acceptAllCookies}>{t('allow')}</button>
                                </div>
                                <div className='cookie-settings'>
                                    <h3>{t('controlManagement')}</h3>
                                    <div className='cookie-setting'>
                                        <div className='setting-controls'>
                                            <h4>{t('necessary')}</h4>
                                            <p>{t('alwaysActive')}</p>
                                        </div>
                                        <p>{t('necessaryInfo')}</p>
                                    </div>
                                    <div className='cookie-setting'>
                                        <div className='setting-controls selection'>
                                            <h4>{t('analytics')}</h4>
                                            <input
                                                id='analytics'
                                                type='checkbox'
                                                name='cookie-category-selection'
                                                aria-checked={this.state.analytics}
                                                role='switch'
                                                className='category-selection'
                                                aria-labelledby='analytics-header'
                                                onChange={this.handleAnalytics}
                                                defaultChecked={this.state.analytics}
                                            />
                                            <label id='analytics-header' htmlFor='analytics'>
                                                <span className='switch' />
                                            </label>
                                        </div>
                                        <p>{t('analyticsInfo')}</p>
                                    </div>
                                    <div className='cookie-setting'>
                                        <div className='setting-controls selection'>
                                            <h4>{t('functional')}</h4>
                                            <input
                                                id='functional'
                                                type='checkbox'
                                                name='cookie-category-selection'
                                                aria-checked={this.state.functional}
                                                role='switch'
                                                className='category-selection'
                                                aria-labelledby='functional-header'
                                                onChange={this.handleFunctional}
                                                defaultChecked={this.state.functional}
                                            />
                                            <label id='functional-header' htmlFor='functional'>
                                                <span className='switch' />
                                            </label>
                                        </div>
                                        <p>{t('functionalInfo')}</p>
                                    </div>
                                    <div className='cookie-setting'>
                                        <div className='setting-controls selection'>
                                            <h4>{t('marketing')}</h4>
                                            <input
                                                id='marketing'
                                                type='checkbox'
                                                name='cookie-category-selection'
                                                aria-checked={this.state.marketing}
                                                role='switch'
                                                className='category-selection'
                                                aria-labelledby='marketing-header'
                                                onChange={this.handleMarketing}
                                                defaultChecked={this.state.marketing}
                                            />
                                            <label id='marketing-header' htmlFor='marketing'>
                                                <span className='switch' />
                                            </label>
                                        </div>
                                        <p>{t('marketingInfo')}</p>
                                    </div>
                                </div>
                            </div>
                        </RenderGate>
                    </div>
                    <RenderGate shouldRender={this.state.settingsOpen}>
                        <div className='cookie-consent-footer'>
                            <button className='skoda-btn btn-primary' onClick={this.closeModal}>{t('confirm')}</button>
                        </div>
                    </RenderGate>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(CookieConsent)
