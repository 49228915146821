import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

import '../custom.css';
import './NavMenu.css';

import logo from '../images/Skoda_LOISTO_tunnus_RGB_musta.png';
//import search from '../images/search.png';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
      };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom skodaplus-header" light>
            <Container>
                
                <NavbarBrand tag={Link} to="/" className="ml-2 mr-5">
                    <img src={logo} alt="SkodaLoisto" className="logo"/>
                </NavbarBrand>
                    
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />

                <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!this.state.collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                                <NavLink className="ml-5" activeClassName="text-skoda-green" to="/content" exact>
                                    <span>
                                        <span>Tutustu Loisto-palveluun</span>
                                    </span>
                                </NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
                {/*
                <Link to={{pathname: "/"}}>
                    <img src={search} alt="Hae" className="mr-3 d-none d-lg-block"></img>
                </Link>
                */}
            </Container>
        </Navbar>

      </header>
    );
  }
}
